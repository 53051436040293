import React from "react";
// import Sorting from "../assets/f7_sort-up (1).svg";
// import More from "../assets/uiw_more (1).svg";

function FaqFeedTable({ data }) {
  return (
    <div className="w-[100%] mt-[24px] text-[0.875rem]">
      <table className="w-[100%] table">
        <thead>
          <tr>
            <td className="pl-[16px] py-[8px] text-[#62646A99] font-poppins lg:w-[70%]">
              {" "}
              <div className="flex items-center gap-[8px]">
                QUESTIONS
                {/* <img src={Sorting} alt="" /> */}
              </div>
            </td>
            <td className="py-[8px] text-[#62646A99] font-poppins lg:w-[10%]">
              {" "}
              <div className="flex items-center justify-center gap-[8px]">
                YES
                {/* <img src={Sorting} alt="" /> */}
              </div>
            </td>
            <td className="p-[8px] text-[#62646A99] font-poppins lg:w-[10%]">
              {" "}
              <div className="flex items-center justify-center gap-[8px]">
                NO
                {/* <img src={Sorting} alt="" /> */}
              </div>
            </td>
            <td className="py-[8px] text-[#62646A99] font-poppins lg:w-[10%]">
              {" "}
              <div className="flex items-center justify-center gap-[8px] pr-3">
                MAYBE
                {/* <img src={Sorting} alt="" /> */}
              </div>
            </td>
            {/* <td className="py-[8px] text-[#62646A99] font-poppins lg:w-[16.6%] text-center">
              Status
            </td>
            <td className="py-[8px] text-[#62646A99] font-poppins lg:w-[16.6%]"></td> */}
          </tr>
        </thead>
        <tbody>
          {data?.map((da) => (
            <tr key={da._id}>
              <td className="px-[16px] py-[16px] text-[#333333] font-medium font-poppins">
                {da.question}
              </td>
              <td className="py-[16px] text-[#333333] font-medium font-poppins text-center">
                {da.yes_count}
              </td>
              <td className="py-[16px] text-[#333333] font-medium text-center font-poppins">
                {da.no_count}
              </td>
              <td className="py-[16px] text-[#333333] text-center font-medium font-poppins">
                {da.maybe_count}
              </td>
              {/* <td className="py-[16px] text-[#333333] font-medium font-poppins text-center">
                Active
              </td>
              <td className="text-[#333333] font-medium font-poppins">
                <div
                  // onClick={() => {
                  //   setSelected(da);
                  //   setIsOpen(true);
                  // }}
                  className="py-[16px] flex items-center justify-center cursor-pointer"
                >
                  <img src={More} alt="" />
                </div>
              </td> */}
            </tr>
          ))}
        </tbody>
      </table>
      
    </div>
  );
}

export default FaqFeedTable;
