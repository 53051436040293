import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Chevron from "../components/assets/ep_arrow-up-bold.svg";
import FilterNavLinks from "../utils/FilterNavLinks";
import axiosInstance from "../axios";

function Sidebar() {
  const [open, setOpen] = useState("");
  const [group, setGroup] = useState([]);
  // let groups = JSON.parse(localStorage.getItem("groups"));
  let SuperAdmin = group?.includes("Super Admin");
  let ViewOnlySuperAdmin = group?.includes("View-only Super Admin");
  let AccountAdmin = group?.includes("Account Admin");
  let PaymentsAdmin = group?.includes("Payment Admin");
  let PayoutAdmin = group?.includes("Payout Admin");
  let AffiliateAdmin = group?.includes("Affiliate Admin");
  let ChatbotOnly = group?.includes("Chatbot-only");
  let navigate = useNavigate();
  // console.log(groups.includes("Account Admin"))

  // console.log(SuperAdmin);
  // console.log(ViewOnlySuperAdmin);
  // console.log(AccountAdmin);
  // console.log(PaymentsAdmin);
  // console.log(PayoutAdmin);
  // console.log(AffiliateAdmin);
  // console.log(ChatbotOnly);
  const navs = FilterNavLinks(
    SuperAdmin,
    ViewOnlySuperAdmin,
    AccountAdmin,
    PaymentsAdmin,
    PayoutAdmin,
    AffiliateAdmin,
    ChatbotOnly
  );

  useEffect(() => {
    axiosInstance
      .get("/dashboard/user-groups/")
      .then((res) => {
        console.log(res);
        localStorage.setItem("email", res.data.user);
        localStorage.setItem("groups", JSON.stringify(res.data.groups));
        setGroup(res.data.groups);
        // setDetails({ email: "", password: "" });
        // setLoading(false);
        // navigate("/home");
      })
      .catch((err) => {
        console.log(err);
        // setLoading(false);
      });
  }, []);

  return (
    <div className="w-[18%] border-r border-r-[#E4E4E4] 2xl:pl-[32px] pl-[12px] py-[24px] hidden lg:block">
      <img
        className="w-[120px] h-[40px]"
        src="https://res.cloudinary.com/ddlupbcws/image/upload/q_auto/v1680791936/logo_2_ft9ja_1_fsh9iz.webp"
        alt=""
      />
      <div className="mt-[16px] flex flex-col text-[0.875rem]">
        {navs?.map((nav) => {
          return nav.title !== "Accounts" &&
            nav.title !== "Payouts" &&
            nav.title !== "More" &&
            nav.title !== "Accounts Requests" &&
            nav.title !== "Receipts" &&
            nav.title !== "Activity Log" ? (
            <Link
              to={nav.link}
              key={nav.id}
              className="flex items-center gap-[8px] py-[8px] relative"
            >
              <img
                src={
                  nav.link === window.location.pathname ||
                  nav.sub[0]?.link === window.location.pathname ||
                  nav.sub[1]?.link === window.location.pathname ||
                  nav.sub[2]?.link === window.location.pathname ||
                  nav.sub[3]?.link === window.location.pathname
                    ? nav.logo
                    : nav.logo_black
                }
                alt=""
              />
              <p
                className={`${
                  nav.link === window.location.pathname ||
                  nav.sub[0]?.link === window.location.pathname ||
                  nav.sub[1]?.link === window.location.pathname ||
                  nav.sub[2]?.link === window.location.pathname ||
                  nav.sub[3]?.link === window.location.pathname
                    ? "text-[#3EB211]"
                    : "text-[#333333]"
                } font-medium font-poppins`}
              >
                {nav.title}
              </p>
              {(nav.link === window.location.pathname ||
                nav.sub[0]?.link === window.location.pathname ||
                nav.sub[1]?.link === window.location.pathname ||
                nav.sub[2]?.link === window.location.pathname) && (
                <div className="bg-[#3EB211] w-[11px] h-[53px] absolute right-0 rounded-tl-[56px] rounded-bl-[56px]"></div>
              )}
            </Link>
          ) : (
            <div
              key={nav.id}
              onClick={() => {
                // window.location.href = `${nav.link}`;
                if (open === nav.title) {
                  setOpen("");
                } else {
                  setOpen(nav.title);
                }
              }}
            >
              <div className="flex items-center gap-[8px] py-[8px] justify-between relative cursor-pointer">
                <div className="flex items-center gap-[8px] w-full mr-[40px]">
                  <img
                    src={
                      nav.link === window.location.pathname ||
                      nav.sub[0]?.link === window.location.pathname ||
                      nav.sub[1]?.link === window.location.pathname ||
                      nav.sub[2]?.link === window.location.pathname
                        ? nav.logo
                        : nav.logo_black
                    }
                    alt=""
                  />
                  <p
                    className={`${
                      nav.link === window.location.pathname ||
                      nav.sub[0]?.link === window.location.pathname ||
                      nav.sub[1]?.link === window.location.pathname ||
                      nav.sub[2]?.link === window.location.pathname
                        ? "text-[#3EB211]"
                        : "text-[#333333]"
                    } font-medium font-poppins`}
                  >
                    {nav.title}
                  </p>
                  <img className="ml-auto" src={Chevron} alt="" />
                </div>
                {(nav.link === window.location.pathname ||
                  nav.sub[0]?.link === window.location.pathname ||
                  nav.sub[1]?.link === window.location.pathname ||
                  nav.sub[2]?.link === window.location.pathname ||
                  nav.sub[3]?.link === window.location.pathname) && (
                  <div className="bg-[#3EB211] w-[11px] h-[53px] absolute right-0 rounded-tl-[56px] rounded-bl-[56px]"></div>
                )}
              </div>
              {open === nav.title && (
                <div className="pl-7 flex flex-col">
                  {nav.sub.map((su) => (
                    <Link to={su.link} key={su.id}>
                      <p className="py-[5px]">{su.title}</p>
                    </Link>
                  ))}
                </div>
              )}
            </div>
          );
        })}
      </div>
      <button
        onClick={() => {
          localStorage.removeItem("access_token");
          localStorage.removeItem("refresh_token");
          navigate("/");
        }}
        className="mt-5 border border-red-700 rounded-lg text-red-700 py-1.5 px-10 font-medium"
      >
        Log out
      </button>
    </div>
  );
}

export default Sidebar;
