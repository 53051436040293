/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Sidebar from "../components/Sidebar";
import Topbar from "../components/Topbar";
import { CSVLink } from "react-csv";
import Search from "../components/assets/flowbite_search-outline (1).svg";
import { Triangle } from "react-loader-spinner";
import Chevron from "../components/assets/ep_arrow-up-bold.svg";
import axiosInstance from "../axios";
import PaystReciTable from "../components/Tables/PaystReciTable";
import DvaReciTable from "../components/Tables/DvaReciTable";

function DvaReceipts() {
  const [data, setData] = useState([]);
  const [nextUrl, setNextUrl] = useState(null);
  const [prevUrl, setPrevUrl] = useState(null);
  const [newUrl, setNewUrl] = useState(null);
  const [total, setTotal] = useState(null);
  const [loading, setLoading] = useState(false);
  const [filterBy, setFilterBy] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedTerm, setDebouncedTerm] = useState(searchTerm);
  const [count, setCount] = useState(0);
  const CSVdata = data;

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedTerm(searchTerm);
    }, 500); // 300ms debounce time

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  useEffect(() => {
    setLoading(true);
    if (debouncedTerm.length > 0) {
      const fetchResults = async () => {
        try {
          const response = await axiosInstance.get(
            `/dashboard/dva-tranfers/${debouncedTerm}/`
          );
          console.log(response.data);
          setNextUrl(response.data.next);
          setPrevUrl(response.data.previous);
          setTotal(response.data.count);
          setData([...response.data.results.receipts]);
          setLoading(false);
        } catch (error) {
          console.error("Error fetching search results", error);
        }
      };

      fetchResults();
    } else {
      axiosInstance
        .get(`${newUrl || "dashboard/dva-transfers/"}`)
        .then((res) => {
          console.log(res);
          setLoading(false);
          setNextUrl(res.data.next);
          setPrevUrl(res.data.previous);
          setTotal(res.data.count);
          setData(res.data.results);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [newUrl, debouncedTerm]);

  return (
    <div className="flex h-[100vh]">
      <Sidebar />
      <div className="w-[100%] lg:w-[82%]">
        <Topbar />
        <div className="lg:p-[32px] p-[16px] h-[88vh] overflow-y-scroll">
          <div className="border border-[#E4E4E4] rounded-[8px] py-[24px] hidden lg:block">
            <div className="flex justify-between px-[16px]">
              <h1 className="font-poppins text-[#333333] font-medium text-[1.125rem]">
                DVA Receipts List
              </h1>
              <div className="flex items-center gap-10 text-[0.875rem]">
                <CSVLink data={CSVdata} filename={"paystack_receipt_data.csv"}>
                  <button className="text-[#3EB211] font-poppins font-medium">
                    Export CSV
                  </button>
                </CSVLink>
                <div className="flex items-center border-b border-b-[#1D1D1D] px-2 pb-2">
                  <img src={Search} alt="" />
                  <input
                    onChange={(e) => setSearchTerm(e.target.value)}
                    value={searchTerm}
                    type="text"
                    placeholder="Search"
                    className="px-3 outline-none font-poppins"
                  />
                </div>
                {/* <button className="border border-[#E4E4E4] flex font-medium items-center font-poppins px-2 py-1 rounded-[8px] gap-[8px]">
                    Filter
                    <img src={Chevron} alt="" />
                  </button> */}
                <div className="flex items-center gap-5 font-poppins font-medium">
                  {prevUrl && (
                    <button
                      onClick={() => {
                        setNewUrl(prevUrl);
                      }}
                      className="bg-white text-[#3EB211] border border-[#3EB211] py-2 px-4 rounded-lg"
                    >
                      Prev
                    </button>
                  )}
                  {nextUrl && (
                    <div>
                      <p>
                        Page {nextUrl?.split("=")[1] - 1} of{" "}
                        {Math.round(total / 50)}
                      </p>
                    </div>
                  )}
                  {nextUrl && (
                    <button
                      onClick={() => {
                        setCount(count + 1);
                        setNewUrl(nextUrl);
                      }}
                      className="bg-[#3EB211] border border-[#3EB211] text-white rounded-lg py-2 px-4"
                    >
                      Next
                    </button>
                  )}
                </div>
              </div>
            </div>
            {loading ? (
              <div className="w-[100%] h-[50vh] flex items-center justify-center">
                <Triangle
                  visible={true}
                  height="80"
                  width="80"
                  color="#3EB211"
                  ariaLabel="triangle-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              </div>
            ) : (
              <DvaReciTable data={data} />
            )}
          </div>

          <div className="lg:hidden">
            <div className="flex items-center justify-between">
              <h1 className="font-poppins text-[#333333] font-medium text-[1.125rem]">
                DVA Receipts List
              </h1>

              <CSVLink data={CSVdata} filename={"paystack_receipt_data.csv"}>
                <button className="text-[#3EB211] font-poppins font-medium">
                  Export CSV
                </button>
              </CSVLink>
            </div>
            <div className="flex flex-col items-center gap-10 text-[0.875rem] mt-[24px]">
              <div className="flex items-center justify-between">
                <div className="flex items-center w-[50%] border-b border-b-[#1D1D1D] px-2 pb-2">
                  <img src={Search} alt="" />
                  <input
                    onChange={(e) => setSearchTerm(e.target.value)}
                    value={searchTerm}
                    type="text"
                    placeholder="Search"
                    className="px-3 outline-none font-poppins"
                  />
                </div>
                {/* <button className="border border-[#E4E4E4] flex font-medium items-center font-poppins px-2 py-1 rounded-[8px] gap-[8px]">
                    Filter
                    <img src={Chevron} alt="" />
                  </button> */}
              </div>
              <div className="flex items-center gap-5 font-poppins font-medium">
                {prevUrl && (
                  <button
                    onClick={() => {
                      setNewUrl(prevUrl);
                    }}
                    className="bg-white text-[#3EB211] border border-[#3EB211] py-2 px-4 rounded-lg"
                  >
                    Prev
                  </button>
                )}
                {nextUrl && (
                  <div>
                    <p>
                      Page {nextUrl?.split("=")[1] - 1} of{" "}
                      {Math.round(total / 50)}
                    </p>
                  </div>
                )}
                {nextUrl && (
                  <button
                    onClick={() => {
                      setCount(count + 1);
                      setNewUrl(nextUrl);
                    }}
                    className="bg-[#3EB211] border border-[#3EB211] text-white rounded-lg py-2 px-4"
                  >
                    Next
                  </button>
                )}
              </div>
            </div>
            {loading ? (
              <div className="w-[100%] h-[50vh] flex items-center justify-center">
                <Triangle
                  visible={true}
                  height="80"
                  width="80"
                  color="#3EB211"
                  ariaLabel="triangle-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              </div>
            ) : (
              <DvaReciTable data={data} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DvaReceipts;
