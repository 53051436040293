import React from "react";
// import Sorting from "../assets/f7_sort-up (1).svg";
// import More from "../assets/uiw_more (1).svg";

function ServerTableUp({ data }) {
  return (
    <div className="w-[100%] mt-[24px]">
      <table className="w-[100%] hidden lg:table">
        <thead>
          <tr>
            <td className="pl-[16px] py-[8px] text-[#62646A99] font-poppins lg:w-[25%]">
              {" "}
              <div className="flex items-center gap-[8px]">
                ACCOUNT TYPE
                {/* <img src={Sorting} alt="" /> */}
              </div>
            </td>
            <td className="py-[8px] text-[#62646A99] font-poppins lg:w-[25%]">
              {" "}
              <div className="flex items-center justify-center gap-[8px]">
                NUMBER OF ACCOUNTS
                {/* <img src={Sorting} alt="" /> */}
              </div>
            </td>
            <td className="py-[8px] text-[#62646A99] font-poppins lg:w-[25%]">
              {" "}
              <div className="flex items-center justify-center gap-[8px]">
                REQUIRED SERVERS
                {/* <img src={Sorting} alt="" /> */}
              </div>
            </td>
            <td className="py-[8px] text-[#62646A99] font-poppins lg:w-[25%]">
              {" "}
              <div className="flex items-center justify-center gap-[8px]">
                AVAILABLE SERVERS
                {/* <img src={Sorting} alt="" /> */}
              </div>
            </td>
            {/* <td className="py-[8px] text-[#62646A99] font-poppins lg:w-[16.6%] text-center">
                  Status
                </td>
                <td className="py-[8px] text-[#62646A99] font-poppins lg:w-[16.6%]"></td> */}
          </tr>
        </thead>
        <tbody>
          {data?.map((da) => (
            <tr key={da._id}>
              <td className="pl-[16px] py-[16px] text-[#333333] font-medium font-poppins">
                {da.name}
              </td>
              <td className="py-[16px] text-[#333333] font-medium font-poppins text-center">
                {da.accts}
              </td>
              <td className="py-[16px] text-[#333333] text-center font-medium font-poppins">
                {da.required}
              </td>
              <td className="py-[16px] text-[#333333] text-center font-medium font-poppins">
                {da.available}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="lg:hidden flex flex-col gap-[16px] w-[100%]">
        <div className="mb-6">
          <h1 className="font-poppins text-[0.75rem] text-[#62646A99] font-medium mb-4">ACCOUNT TYPE</h1>
          {data?.map((ser) => (
            <p className="text-[#333333] font-poppins font-medium text-[0.875rem] mb-2" key={ser.name}>{ser.name}</p>
          ))}
        </div>
        <div className="mb-6">
          <h1 className="font-poppins text-[0.75rem] text-[#62646A99] font-medium mb-4">NUMBER OF ACCOUNTS</h1>
          {data?.map((ser) => (
            <p className="text-[#333333] font-poppins font-medium text-[0.875rem] mb-2" key={ser.name}>{ser.accts}</p>
          ))}
        </div>
        <div className="mb-6">
          <h1 className="font-poppins text-[0.75rem] text-[#62646A99] font-medium mb-4">REQUIRED SERVERS</h1>
          {data?.map((ser) => (
            <p className="text-[#333333] font-poppins font-medium text-[0.875rem] mb-2" key={ser.name}>{ser.required}</p>
          ))}
        </div>
        <div className="mb-6">
          <h1 className="font-poppins text-[0.75rem] text-[#62646A99] font-medium mb-4">AVAILABLE SERVERS</h1>
          {data?.map((ser) => (
            <p className="text-[#333333] font-poppins font-medium text-[0.875rem] mb-2" key={ser.name}>{ser.available}</p>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ServerTableUp;
