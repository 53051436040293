import React from "react";
import Sorting from "../assets/f7_sort-up (1).svg";
import More from "../assets/uiw_more (1).svg";
import convertDate from "../../utils/ConvertDate";

function UsersTable({ data, setSelectedUser, setOpen }) {
  return (
    <div className="w-[100%] mt-[24px]">
      <table className="w-[100%] hidden lg:table">
        <thead className="bg-[#F1F1F1] text-[0.875rem]">
          <tr>
            <td className="pl-[16px] py-[8px] text-[#62646A99] font-poppins lg:w-[14.3%]">
              {" "}
              <div className="flex items-center gap-[8px]">
                User
                <img src={Sorting} alt="" />
              </div>
            </td>
            <td className="py-[8px] text-[#62646A99] font-poppins lg:w-[14.3%]">
              {" "}
              <div className="flex items-center justify-center gap-[8px]">
                Phone No.
                <img src={Sorting} alt="" />
              </div>
            </td>
            <td className="py-[8px] text-[#62646A99] font-poppins lg:w-[14.3%]">
              {" "}
              <div className="flex items-center justify-center gap-[8px]">
                Country
                <img src={Sorting} alt="" />
              </div>
            </td>
            <td className="py-[8px] text-[#62646A99] font-poppins lg:w-[14.3%]">
              {" "}
              <div className="flex items-center justify-center gap-[8px]">
                No. of A/Cs
                <img src={Sorting} alt="" />
              </div>
            </td>
            <td className="py-[8px] text-[#62646A99] font-poppins lg:w-[14.3%] text-center">
              {" "}
              <div className="flex items-center justify-center gap-[8px]">
                Registration Date
                <img src={Sorting} alt="" />
              </div>
            </td>
            <td className="py-[8px] text-[#62646A99] font-poppins lg:w-[14.3%]">
              {" "}
              <div className="flex items-center justify-center gap-[8px]">
                Status
                <img src={Sorting} alt="" />
              </div>
            </td>
            {!localStorage
              .getItem("groups")
              ?.includes("View-only Super Admin") && (
              <td className="py-[8px] text-[#62646A99] font-poppins lg:w-[14.3%]"></td>
            )}
          </tr>
        </thead>
        <tbody className="text-[0.875rem]">
          {data?.map((da) => (
            <tr key={da._id}>
              <td className="pl-[16px] py-[16px] text-[#333333] font-medium font-poppins">
                {da.user.firstname}{" "}
                <p className="text-[#62646A99] text-[0.75rem] font-medium">
                  {da.user.email}
                </p>
              </td>
              <td className="py-[16px] text-[#333333] font-medium font-poppins text-center">
                +{da.user.phone}
              </td>
              <td className="py-[16px] text-[#333333] font-medium font-poppins text-center">
                {da.country}
              </td>
              <td className="py-[16px] text-[#333333] text-center font-medium font-poppins">
                {da.num_of_accounts}
              </td>
              <td className="py-[16px] text-[#333333] font-medium font-poppins text-center">
                {convertDate(da.date_joined)}
              </td>
              <td className="py-[16px] font-medium font-poppins flex items-center justify-center">
                <p
                  className={`${
                    da.status === "active"
                      ? "bg-[#72E52C1A] text-[#72E52C]"
                      : "bg-[#B410071A] text-[#B41007]"
                  } text-[0.875rem] font-medium rounded-[200px] px-2 w-max text-center py-1`}
                >
                  {da.status}
                </p>
              </td>
              {!localStorage
                .getItem("groups")
                ?.includes("View-only Super Admin") && (
                <td className="text-[#333333] font-medium font-poppins">
                  <div
                    onClick={() => {
                      setSelectedUser(da);
                      setOpen(true);
                    }}
                    className="py-[16px] flex items-center justify-center cursor-pointer"
                  >
                    <img src={More} alt="" />
                  </div>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
      <div className="lg:hidden flex flex-col gap-[16px] w-[100%]">
        {data.map((da) => (
          <div
            key={da._id}
            className="flex flex-col border border-[#C8C8C8] rounded-lg p-4 space-y-6 font-poppins"
          >
            {!localStorage
              .getItem("groups")
              ?.includes("View-only Super Admin") && (
              <div
                onClick={() => {
                  setSelectedUser(da);
                  setOpen(true);
                }}
                className="flex justify-end cursor-pointer"
              >
                <img src={More} alt="" />
              </div>
            )}
            <div className="flex items-center justify-between flex-wrap gap-6">
              <div>
                <p className="text-[0.75rem] text-[#62646A99] mb-1">USER</p>
                <p className="text-[0.875rem] text-[#333333] font-medium">
                  {" "}
                  {da.user.firstname}
                  <p className="text-[#62646A99] text-[0.75rem] font-medium">
                    {da.user.email}
                  </p>
                </p>
              </div>
              <div>
                <p className="text-[0.75rem] text-[#62646A99] mb-1">
                  PHONE NO.
                </p>
                <p className="text-[0.875rem] text-[#333333] font-medium">
                  +{da.user.phone}
                </p>
              </div>
            </div>
            <div className="flex items-center justify-between flex-wrap gap-6">
              <div>
                <p className="text-[0.75rem] text-[#62646A99] mb-1">COUNTRY</p>
                <p className="text-[0.875rem] text-[#333333] font-medium">
                  {" "}
                  {da.country || "Not provided"}
                </p>
              </div>
              <div>
                <p className="text-[0.75rem] text-[#62646A99] mb-1">
                  REGISTRATION DATE
                </p>
                <p className="text-[0.875rem] text-[#333333] font-medium">
                  {convertDate(da.date_joined)}
                </p>
              </div>
            </div>
            <div className="flex items-center justify-between">
              <div>
                <p className="text-[0.75rem] text-[#62646A99] mb-1">STATUS</p>
                <p
                  className={`${
                    da.status === "active"
                      ? "bg-[#72E52C1A] text-[#72E52C]"
                      : "bg-[#B410071A] text-[#B41007]"
                  } text-[0.875rem] font-medium rounded-[200px] px-2 py-1`}
                >
                  {da.status}
                </p>
              </div>
              <div>
                <p className="text-[0.75rem] text-[#62646A99] mb-1">
                  NO. OF A/Cs
                </p>
                <p className="text-[0.875rem] text-[#333333] font-medium text-center">
                  {da.num_of_accounts}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default UsersTable;
