/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Sidebar from "../components/Sidebar";
import Topbar from "../components/Topbar";
import ServerTableUp from "../components/Tables/ServerTableUp";
import ServerTableBottom from "../components/Tables/ServerTableBottom";
import axiosInstance from "../axios";
import { Triangle } from "react-loader-spinner";

function Servers() {
  const [topData, setTopData] = useState([]);
  const [bottomData, setBottomData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [nextUrl, setNextUrl] = useState(null);
  const [prevUrl, setPrevUrl] = useState(null);
  const [newUrl, setNewUrl] = useState(null);
  const [total, setTotal] = useState(null);

  useEffect(() => {
    setLoading(true);
    axiosInstance
      .get(`${newUrl || "dashboard/servers/"}`)
      .then((res) => {
        console.log(res);
        setLoading(false);
        setNextUrl(res.data.next);
        setPrevUrl(res.data.previous);
        setTotal(res.data.count);
        setTopData([
          {
            name: "Deriv",
            accts: res.data.deriv,
            available: res.data.deriv_available,
            required: res.data.deriv_required,
          },
          {
            name: "FXTM",
            accts: res.data.fxtm,
            available: res.data.fxtm_available,
            required: res.data.fxtm_required,
          },
          {
            name: "IC Markets",
            accts: res.data.icm,
            available: res.data.icm_available,
            required: res.data.icm_required,
          },
        ]);

        setBottomData([
          {
            name: "Deriv",
            date: res.data.deriv_checked[0][0],
            checked: res.data.deriv_checked[0][1],
          },
          {
            name: "FXTM",
            date: res.data.fxtm_checked[0],
            checked: res.data.fxtm_checked[1],
          },
          {
            name: "IC Markets",
            date: res.data.icm_checked[0],
            checked: res.data.icm_checked[1],
          },
        ]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [newUrl]);
  return (
    <div className="flex h-[100vh]">
      <Sidebar />
      <div className="lg:w-[82%] w-[100%]">
        <Topbar />
        <div className="p-8 overflow-y-scroll h-[88vh]">
          <div className="border border-[#E4E4E4] rounded-lg p-6">
            <h1 className="text-center text-[1.125rem] font-medium text-[#333333] mb-6">
              Server Info MT5
            </h1>
            {loading ? (
              <div className="w-[100%] h-[50vh] flex items-center justify-center">
                <Triangle
                  visible={true}
                  height="80"
                  width="80"
                  color="#3EB211"
                  ariaLabel="triangle-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              </div>
            ) : (
              <div className="flex items-center justify-between border border-[#E4E4E4] rounded-lg p-6 mb-4">
                <ServerTableUp data={topData} />
              </div>
            )}

            {loading ? (
              <div className="w-[100%] h-[50vh] flex items-center justify-center">
                <Triangle
                  visible={true}
                  height="80"
                  width="80"
                  color="#3EB211"
                  ariaLabel="triangle-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              </div>
            ) : (
              <div className="flex items-center justify-between border border-[#E4E4E4] rounded-lg p-6">
                <ServerTableBottom data={bottomData} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Servers;
