import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./pages/Login";
import Analytics from "./pages/Analytics";
import Violations from "./pages/Violations";
import Team from "./pages/Team";
import Receipts from "./pages/Receipts";
import Discounts from "./pages/Discounts";
import Sca from "./pages/Sca";
import Verifications from "./pages/Verifications";
import Codes from "./pages/Codes";
import PayoutRequests from "./pages/PayoutRequests";
import RefPayReq from "./pages/RefPayReq";
import FaqFeed from "./pages/FaqFeed";
import UserStats from "./pages/UserStats";
import Servers from "./pages/Servers";
import Users from "./pages/Users";
import Accounts from "./pages/Accounts";
import Fca from "./pages/Fca";
import Aso from "./pages/Aso";
import AsoRequests from "./pages/AsoRequests";
import ScaRequest from "./pages/ScaRequest";
import VerificationRequest from "./pages/VerificationRequest";
import TransferReceipts from "./pages/TransferReceipts";
import PaystackReceipts from "./pages/PaystackReceipts";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ChatBot from "./pages/ChatBot";
import ChatbotFeed from "./pages/ChatbotFeed";
import DvaReceipts from "./pages/DvaReceipts";
import UsersLog from "./pages/UsersLog";
import AdminsLog from "./pages/AdminsLog";
import { useState } from "react";
import SoundAlert from "./components/SoundAlert";

function App() {
  const [alert, setAlert] = useState(false);
  const [isUserInteracted, setIsUserInteracted] = useState(false);

  const handleNotification = () => {
    setAlert(true);
    setTimeout(() => setAlert(false), 100);  // Reset alert after 1 second
  };
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/home" element={<Analytics />} />
          <Route path="/accounts" element={<Accounts />} />
          <Route path="/violations" element={<Violations />} />
          <Route path="/users" element={<Users />} />
          <Route path="/team" element={<Team />} />
          <Route path="/receipts" element={<Receipts />} />
          <Route path="/discounts" element={<Discounts />} />
          <Route path="/sca" element={<Sca />} />
          <Route path="/fca" element={<Fca />} />
          <Route path="/aso" element={<Aso />} />
          <Route path="/chatbot" element={<ChatBot handleNotification={handleNotification} isUserInteracted={isUserInteracted} setIsUserInteracted={setIsUserInteracted} />} />
          <Route path="/aso-request" element={<AsoRequests />} />
          <Route path="/sca-request" element={<ScaRequest />} />
          <Route path="/verifi-request" element={<VerificationRequest />} />
          <Route path="/transfer-receipts" element={<TransferReceipts />} />
          <Route path="/paystack-receipts" element={<PaystackReceipts />} />
          <Route path="/dva-receipts" element={<DvaReceipts />} />
          <Route path="/verifications" element={<Verifications />} />
          <Route path="/codes" element={<Codes />} />
          <Route path="/requests" element={<PayoutRequests />} />
          <Route path="/ref_req" element={<RefPayReq />} />
          <Route path="/feedbacks" element={<FaqFeed />} />
          <Route path="/users_log" element={<UsersLog />} />
          <Route path="/admin_log" element={<AdminsLog />} />
          <Route path="/chatbot_feedbacks" element={<ChatbotFeed />} />
          <Route path="/stats" element={<UserStats />} />
          <Route path="/servers" element={<Servers />} />
          <Route exact path="/" element={<Login />} />
        </Routes>
      </BrowserRouter>
      <ToastContainer
        position="top-right"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <SoundAlert alert={alert} isUserInteracted={isUserInteracted} />
    </div>
  );
}

export default App;
