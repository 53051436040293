import React, { useEffect, useState } from "react";
import axiosInstance from "../axios";
import { useNavigate } from "react-router-dom";

function Login() {
  const [details, setDetails] = useState({
    email: "",
    password: "",
  });
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    sessionStorage.removeItem("hasReloaded")
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
  }, [])

  const handleSubmit = async () => {
    setLoading(true);
    try {
      axiosInstance
        .post("dashboard/login/", {
          email: details.email,
          password: details.password,
        })
        .then((res) => {
          console.log(res);
          localStorage.setItem("access_token", res.data.access);
          localStorage.setItem("refresh_token", res.data.refresh);
          setDetails({ email: "", password: "" });
          setLoading(false);
          navigate("/home");
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="flex items-center justify-center w-[100%] h-[100vh] font-poppins">
      <div className="border border-black rounded-lg p-3 w-[90%] lg:w-[25%]">
        <div className="flex flex-col mb-10">
          <label htmlFor="">Email</label>
          <input
            onChange={(e) => setDetails({ ...details, email: e.target.value })}
            type="email"
            value={details.email}
            className="border border-black rounded-lg py-1 px-2 text-[0.875rem]"
          />
        </div>
        <div className="flex flex-col">
          <label htmlFor="">Password</label>
          <input
            onChange={(e) =>
              setDetails({ ...details, password: e.target.value })
            }
            type="password"
            value={details.password}
            className="border border-black rounded-lg py-1 px-2 text-[0.875rem]"
          />
        </div>
        <button
          disabled={loading}
          onClick={handleSubmit}
          className="bg-[#3EB211] disabled:bg-gray-600 text-white rounded-lg w-[100%] mt-10 py-3 font-medium"
        >
          {loading ? "Please Wait..." : "Login"}
        </button>
      </div>
    </div>
  );
}

export default Login;
