/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import Sidebar from "../components/Sidebar";
import Topbar from "../components/Topbar";
import { CSVLink } from "react-csv";
import Search from "../components/assets/flowbite_search-outline (1).svg";
import ReactPaginate from "react-paginate";
import { Triangle } from "react-loader-spinner";
import Chevron from "../components/assets/ep_arrow-up-bold.svg";
import UsersTable from "../components/Tables/UsersTable";

function Receipts() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filterBy, setFilterBy] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const CSVdata = data;
  const [itemOffset, setItemOffset] = useState(0);

  const itemsPerPage = 10;

  const endOffset = itemOffset + itemsPerPage;
  const currentItems =
    filteredData?.length > 0 && filterBy !== ""
      ? filteredData?.slice(itemOffset, endOffset)
      : filterBy === ""
      ? data?.slice(itemOffset, endOffset)
      : [];
  const pageCount =
    filteredData?.length > 0 && filterBy !== ""
      ? Math.ceil(filteredData?.length / itemsPerPage)
      : filterBy === ""
      ? Math.ceil(data?.length / itemsPerPage)
      : Math.ceil([].length / itemsPerPage);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % data.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };

  return (
    <div className="flex h-[100vh]">
      <Sidebar />
      <div className="w-[100%] lg:w-[82%]">
        <Topbar />
        <div className="lg:p-[32px] p-[16px] h-[88vh] overflow-y-scroll">
          <div className="border border-[#E4E4E4] rounded-[8px] py-[24px] hidden lg:block">
            <div className="flex justify-between px-[16px]">
              <h1 className="font-poppins text-[#333333] font-medium text-[1.125rem]">
                Receipts List
              </h1>
              <div className="flex items-center gap-10 text-[0.875rem]">
                <CSVLink data={CSVdata} filename={"receipts_data.csv"}>
                  <button className="text-[#3EB211] font-poppins font-medium">
                    Export CSV
                  </button>
                </CSVLink>
                <div className="flex items-center border-b border-b-[#1D1D1D] px-2 pb-2">
                  <img src={Search} alt="" />
                  <input
                    onChange={(e) => setFilterBy(e.target.value)}
                    type="text"
                    placeholder="Search"
                    className="px-3 outline-none font-poppins"
                  />
                </div>
                <button className="border border-[#E4E4E4] flex font-medium items-center font-poppins px-2 py-1 rounded-[8px] gap-[8px]">
                  Filter
                  <img src={Chevron} alt="" />
                </button>
                <ReactPaginate
                  breakLabel="..."
                  nextLabel=">"
                  nextClassName="font-bold text-black"
                  previousClassName="font-bold text-black"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={2}
                  pageCount={pageCount}
                  previousLabel="<"
                  // renderOnZeroPageCount={null}
                  marginPagesDisplayed={1}
                  activeClassName={
                    "bg-[#3EB211] rounded-full w-[24px] h-[20px] text-white flex items-center justify-center"
                  }
                  className="flex gap-4 text-[16px] items-center text-[#62646A99] font-poppins"
                />
              </div>
            </div>
            {loading ? (
              <div className="w-[100%] h-[50vh] flex items-center justify-center">
                <Triangle
                  visible={true}
                  height="80"
                  width="80"
                  color="#3EB211"
                  ariaLabel="triangle-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              </div>
            ) : (
              <UsersTable data={currentItems} />
            )}
          </div>

          <div className="lg:hidden">
            <div className="flex items-center justify-between">
              <h1 className="font-poppins text-[#333333] font-medium text-[1.125rem]">
                Receipts List
              </h1>

              <CSVLink data={CSVdata} filename={"receipts_data.csv"}>
                <button className="text-[#3EB211] font-poppins font-medium">
                  Export CSV
                </button>
              </CSVLink>
            </div>
            <div className="flex flex-col items-center gap-10 text-[0.875rem] mt-[24px]">
              <div className="flex items-center justify-between">
                <div className="flex items-center w-[50%] border-b border-b-[#1D1D1D] px-2 pb-2">
                  <img src={Search} alt="" />
                  <input
                    onChange={(e) => setFilterBy(e.target.value)}
                    type="text"
                    placeholder="Search"
                    className="px-3 outline-none font-poppins"
                  />
                </div>
                <button className="border border-[#E4E4E4] flex font-medium items-center font-poppins px-2 py-1 rounded-[8px] gap-[8px]">
                  Filter
                  <img src={Chevron} alt="" />
                </button>
              </div>
              <ReactPaginate
                breakLabel="..."
                nextLabel=">"
                nextClassName="font-bold text-black"
                previousClassName="font-bold text-black"
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={pageCount}
                previousLabel="<"
                // renderOnZeroPageCount={null}
                marginPagesDisplayed={1}
                activeClassName={
                  "bg-[#3EB211] rounded-full w-[24px] h-[20px] text-white flex items-center justify-center"
                }
                className="flex gap-4 text-[16px] items-center text-[#62646A99] font-poppins"
              />
            </div>
            {loading ? (
              <div className="w-[100%] h-[50vh] flex items-center justify-center">
                <Triangle
                  visible={true}
                  height="80"
                  width="80"
                  color="#3EB211"
                  ariaLabel="triangle-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              </div>
            ) : (
              <UsersTable data={currentItems} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Receipts;
