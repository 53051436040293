import React from "react";
import Sidebar from "../components/Sidebar";
import Topbar from "../components/Topbar";

function Codes() {
  return (
    <div className="flex h-[100vh]">
      <Sidebar />
      <div className="lg:w-[82%] w-[100%]">
        <Topbar />
      </div>
    </div>
  );
}

export default Codes;
