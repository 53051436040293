import React from "react";
import Sorting from "../assets/f7_sort-up (1).svg";
// import More from "../assets/uiw_more (1).svg";
import convertDate from "../../utils/ConvertDate";

function ViolationTable({ data }) {
  return (
    <div className="w-[100%] mt-[24px] text-[0.87rem]">
      <table className="w-[100%] hidden xl:table">
        <thead className="bg-[#F1F1F1] text-[0.8rem]">
          <tr>
            <td className="pl-[16px] py-[8px] text-[#62646A99] font-poppins lg:w-[14.3%]">
              {" "}
              <div className="flex items-center gap-[8px]">
                User
                <img src={Sorting} alt="" />
              </div>
            </td>
            <td className="py-[8px] text-[#62646A99] font-poppins lg:w-[14.3%]">
              {" "}
              <div className="flex items-center justify-center gap-[8px]">
                A/C No.
                <img src={Sorting} alt="" />
              </div>
            </td>
            <td className="py-[8px] text-[#62646A99] font-poppins lg:w-[14.3%]">
              {" "}
              <div className="flex items-center justify-center gap-[8px]">
                Violation Type
                <img src={Sorting} alt="" />
              </div>
            </td>
            <td className="py-[8px] text-[#62646A99] font-poppins lg:w-[14.3%]">
              {" "}
              <div className="flex items-center justify-center gap-[8px]">
                Date
                <img src={Sorting} alt="" />
              </div>
            </td>
            <td className="py-[8px] text-[#62646A99] font-poppins lg:w-[14.3%]">
              {" "}
              <div className="flex items-center justify-center gap-[8px]">
                Violation %
                <img src={Sorting} alt="" />
              </div>
            </td>
            <td className="py-[8px] text-[#62646A99] font-poppins lg:w-[14.3%]">
              {" "}
              <div className="flex items-center justify-center gap-[8px]">
                Status
                <img src={Sorting} alt="" />
              </div>
            </td>
            {/* <td className="py-[8px] text-[#62646A99] font-poppins lg:w-[14.3%]"></td> */}
          </tr>
        </thead>
        <tbody>
          {data?.map((da) => (
            <tr key={da._id}>
              <td className="pl-[16px] py-[16px] text-[#333333] font-medium font-poppins">
                {da.fullname}{" "}
                <p className="text-[#62646A99] text-[0.75rem] font-medium">
                  {da.user}
                </p>
              </td>
              <td className="py-[16px] text-[#333333] font-medium font-poppins text-center">
                {da.account}
              </td>
              <td className="py-[16px] text-[#333333] text-center font-medium font-poppins">
                {da.violation_type}
              </td>
              <td className="py-[16px] font-medium text-center font-poppins">
                {convertDate(da.created)}
              </td>
              <td className="py-[16px] font-medium text-center font-poppins">
                {da.percent}
              </td>
              <td className="py-[16px] font-medium flex items-center justify-center text-center font-poppins">
                <p
                  className={`${
                    da.status.toLowerCase() === "confirmed"
                      ? "bg-[#72E52C1A] text-[#72E52C]"
                      : da.status.toLowerCase() === "unprocessed"
                      ? "bg-[#EDBB071A] text-[#EDBB07]"
                      : "bg-[#B410071A] text-[#B41007]"
                  } text-[0.875rem] font-medium rounded-[200px] px-2 w-max text-center py-1`}
                >
                  {da.status}
                </p>
              </td>
              {/* <td className="text-[#333333] font-medium font-poppins">
                <div
                  // onClick={() => {
                  //   setSelected(da);
                  //   setIsOpen(true);
                  // }}
                  className="py-[16px] flex items-center justify-center cursor-pointer"
                >
                  <img src={More} alt="" />
                </div>
              </td> */}
            </tr>
          ))}
        </tbody>
      </table>
      <div className="xl:hidden lg:mt-10 flex flex-col items-center gap-[16px] w-[100%]">
        {data.map((da) => (
          <div
            key={da._id}
            className="flex flex-col border border-[#C8C8C8] rounded-lg p-4 space-y-6 font-poppins w-[100%] lg:w-[80%]"
          >
            {/* <div
              // onClick={() => {
              //   setSelected(da);
              //   setIsOpen(true);
              // }}
              className="flex justify-end cursor-pointer"
            >
              <img src={More} alt="" />
            </div> */}
            <div className="flex items-center justify-between flex-wrap gap-6">
              <div>
                <p className="text-[0.75rem] text-[#62646A99] mb-1">USER</p>
                <p className="text-[0.875rem] text-[#333333] font-medium">
                  {" "}
                  {da.fullname}
                  <p className="text-[#62646A99] text-[0.75rem] font-medium">
                    {da.user}
                  </p>
                </p>
              </div>
              <div>
                <p className="text-[0.75rem] text-[#62646A99] mb-1">A/C NO.</p>
                <p className="text-[0.875rem] text-[#333333] font-medium">
                  {da.account}
                </p>
              </div>
            </div>
            <div className="flex items-center justify-between flex-wrap gap-6">
              <div>
                <p className="text-[0.75rem] text-[#62646A99] mb-1">
                  VIOLATION TYPE
                </p>
                <p className="text-[0.875rem] text-[#333333] font-medium">
                  {" "}
                  {da.violation_type}
                </p>
              </div>
              <div>
                <p className="text-[0.75rem] text-[#62646A99] mb-1">DATE</p>
                <p className="text-[0.875rem] text-[#333333] font-medium">
                  {convertDate(da.created)}
                </p>
              </div>
            </div>
            <div className="flex items-center justify-between flex-wrap gap-6">
              <div>
                <p className="text-[0.75rem] text-[#62646A99] mb-1">
                  VIOLATION %
                </p>
                <p className="text-[0.875rem] text-[#333333] font-medium">
                  {" "}
                  {da.percent}
                </p>
              </div>
              <div>
                <p className="text-[0.75rem] text-[#62646A99] mb-1">STATUS</p>
                <p
                  className={`${
                    da.status.toLowerCase() === "confirmed"
                      ? "bg-[#72E52C1A] text-[#72E52C]"
                      : da.status.toLowerCase() === "unprocessed"
                      ? "bg-[#EDBB071A] text-[#EDBB07]"
                      : "bg-[#B410071A] text-[#B41007]"
                  } text-[0.875rem] font-medium rounded-[200px] px-2 w-max text-center py-1`}
                >
                  {da.status}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ViolationTable;
