/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Sidebar from "../components/Sidebar";
import Topbar from "../components/Topbar";
import { CSVLink } from "react-csv";
import Search from "../components/assets/flowbite_search-outline (1).svg";
import { Triangle } from "react-loader-spinner";
import Chevron from "../components/assets/ep_arrow-up-bold.svg";
import axiosInstance from "../axios";
import DiscountsTable from "../components/Tables/DiscountsTable";
import { Dialog, DialogPanel } from "@headlessui/react";
import { toast } from "react-toastify";
import convertDate from "../utils/ConvertDate";

function Discounts() {
  const [data, setData] = useState([]);
  const [nextUrl, setNextUrl] = useState(null);
  const [prevUrl, setPrevUrl] = useState(null);
  const [newUrl, setNewUrl] = useState(null);
  const [total, setTotal] = useState(null);
  const [loading, setLoading] = useState(false);
  const [filterBy, setFilterBy] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedTerm, setDebouncedTerm] = useState(searchTerm);
  const [count, setCount] = useState(0);
  const [openCreate, setOpenCreate] = useState(false);
  const [createLoading, setCreateLoading] = useState(false);
  const [discountDetails, setDiscountDetails] = useState({
    code: "",
    discount_percentage: "",
    type: "general",
    expiry_date: "",
    user_email: "",
    is_active: true,
    description: "",
    num_of_accounts: "",
    acc_size: "",
    acc_model: "",
  });
  const [selectedDiscount, setSelectedDiscount] = useState();
  const [openSelected, setOpenSelected] = useState(false);
  const CSVModaldata = selectedDiscount ? [selectedDiscount] : [];

  const CSVdata = data;

  console.log(selectedDiscount);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedTerm(searchTerm);
    }, 500); // 300ms debounce time

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  useEffect(() => {
    setLoading(true);
    if (debouncedTerm.length > 0) {
      const fetchResults = async () => {
        try {
          const response = await axiosInstance.get(
            `/dashboard/discounts/${debouncedTerm}/`
          );
          console.log(response.data);
          setNextUrl(response.data.next);
          setPrevUrl(response.data.previous);
          setTotal(response.data.count);
          setData(response.data.results);
          setLoading(false);
        } catch (error) {
          console.error("Error fetching search results", error);
        }
      };

      fetchResults();
    } else {
      axiosInstance
        .get(`${newUrl || "dashboard/discounts/"}`)
        .then((res) => {
          console.log(res);
          setLoading(false);
          setNextUrl(res.data.next);
          setPrevUrl(res.data.previous);
          setTotal(res.data.count);
          setData(res.data.results);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [newUrl, debouncedTerm, refresh]);

  function createDiscount() {
    setCreateLoading(true);
    if (
      discountDetails.code === "" ||
      discountDetails.discount_percentage === "" ||
      discountDetails.type === "" ||
      discountDetails.expiry_date === ""
    ) {
      toast.info(
        "Code, Discount Percentage, Type and Expiry date are required",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );
      setCreateLoading(false);
    } else {
      const removeEmptyStrings = (obj) => {
        Object.keys(obj).forEach((key) => {
          if (obj[key] === "") {
            delete obj[key];
          }
        });
        return obj;
      };

      let newData = removeEmptyStrings(discountDetails);
      toast.info("Please Wait...", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      axiosInstance
        .post(`/dashboard/discounts/`, newData)
        .then((res) => {
          console.log(res);
          setCreateLoading(false);
          setDiscountDetails({
            code: "",
            discount_percentage: "",
            type: "general",
            expiry_date: "",
            user_email: "",
            is_active: true,
            description: "",
            num_of_accounts: "",
            acc_size: "",
            acc_model: "",
          });
          setRefresh(!refresh);
          setOpenCreate(false);

          toast.success("Successfully added Discount Code", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          // setSelectedselectedDiscountAccounts([...res.data.accounts, ...res.data.scas]);
          // setNextUrl(res.data.next);
          // setPrevUrl(res.data.previous);
          // setTotal(res.data.count);
          // setData(res.data.results);
        })
        .catch((err) => {
          console.log(err);
          toast.error(
            `${err.response.data.error || "Failed to add Diacount Code"}`,
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            }
          );
          setCreateLoading(false);
        });
    }
  }

  function deleteDiscount() {
    setCreateLoading(true);

    toast.info("Please Wait...", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    axiosInstance
      .delete(`/dashboard/discounts/${selectedDiscount?.id}/delete/`)
      .then((res) => {
        console.log(res);
        setCreateLoading(false);
        setDiscountDetails({
          code: "",
          discount_percentage: "",
          type: "general",
          expiry_date: "",
          user_email: "",
          is_active: true,
          description: "",
          num_of_accounts: "",
          acc_size: "",
          acc_model: "",
        });
        setRefresh(!refresh);
        setOpenSelected(false);

        toast.success("Successfully deleted Discount Code", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        // setSelectedselectedDiscountAccounts([...res.data.accounts, ...res.data.scas]);
        // setNextUrl(res.data.next);
        // setPrevUrl(res.data.previous);
        // setTotal(res.data.count);
        // setData(res.data.results);
      })
      .catch((err) => {
        console.log(err);
        toast.error(
          `${err.response.data.error || "Failed to delete Diacount Code"}`,
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
        setCreateLoading(false);
      });
  }

  return (
    <div className="flex h-[100vh]">
      <Sidebar />
      <div className="w-[100%] lg:w-[82%]">
        <Topbar />
        <div className="lg:p-[32px] p-[16px] h-[88vh] overflow-y-scroll">
          <div className="border border-[#E4E4E4] rounded-[8px] py-[24px] hidden lg:block">
            {!localStorage
              .getItem("groups")
              ?.includes("View-only Super Admin") && (
              <div className="mb-10 flex justify-end pr-4">
                <button
                  onClick={() => {
                    setOpenCreate(true);
                  }}
                  className="bg-[#3EB211] font-semibold border border-[#3EB211] text-white rounded-lg py-2 px-4"
                >
                  Add Discount Code
                </button>
              </div>
            )}
            <div className="flex justify-between px-[16px]">
              <h1 className="font-poppins text-[#333333] font-medium text-[1.125rem]">
                Discount List
              </h1>
              <div className="flex items-center gap-10 text-[0.875rem]">
                <CSVLink data={CSVdata} filename={"discounts_data.csv"}>
                  <button className="text-[#3EB211] font-poppins font-medium">
                    Export CSV
                  </button>
                </CSVLink>
                <div className="flex items-center border-b border-b-[#1D1D1D] px-2 pb-2">
                  <img src={Search} alt="" />
                  <input
                    onChange={(e) => setSearchTerm(e.target.value)}
                    value={searchTerm}
                    type="text"
                    placeholder="Search"
                    className="px-3 outline-none font-poppins"
                  />
                </div>
                {/* <button className="border border-[#E4E4E4] flex font-medium items-center font-poppins px-2 py-1 rounded-[8px] gap-[8px]">
                  Filter
                  <img src={Chevron} alt="" />
                </button> */}
                <div className="flex items-center gap-5 font-poppins font-medium">
                  {prevUrl && (
                    <button
                      onClick={() => {
                        setNewUrl(prevUrl);
                      }}
                      className="bg-white text-[#3EB211] border border-[#3EB211] py-2 px-4 rounded-lg"
                    >
                      Prev
                    </button>
                  )}
                  {nextUrl && (
                    <div>
                      <p>
                        Page {nextUrl?.split("=")[1] - 1} of{" "}
                        {Math.round(total / 50)}
                      </p>
                    </div>
                  )}
                  {nextUrl && (
                    <button
                      onClick={() => {
                        setCount(count + 1);
                        setNewUrl(nextUrl);
                      }}
                      className="bg-[#3EB211] border border-[#3EB211] text-white rounded-lg py-2 px-4"
                    >
                      Next
                    </button>
                  )}
                </div>
              </div>
            </div>
            {loading ? (
              <div className="w-[100%] h-[50vh] flex items-center justify-center">
                <Triangle
                  visible={true}
                  height="80"
                  width="80"
                  color="#3EB211"
                  ariaLabel="triangle-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              </div>
            ) : (
              <DiscountsTable
                data={data}
                setSelectedDiscount={setSelectedDiscount}
                setOpenSelected={setOpenSelected}
              />
            )}
          </div>

          <div className="lg:hidden">
            {!localStorage
              .getItem("groups")
              ?.includes("View-only Super Admin") && (
              <div className="mb-10 flex justify-end pr-4">
                <button
                  onClick={() => {
                    setOpenCreate(true);
                  }}
                  className="bg-[#3EB211] font-semibold border border-[#3EB211] text-white rounded-lg py-2 px-4"
                >
                  Add Discount Code
                </button>
              </div>
            )}
            <div className="flex items-center justify-between">
              <h1 className="font-poppins text-[#333333] font-medium text-[1.125rem]">
                Discount List
              </h1>

              <CSVLink data={CSVdata} filename={"discounts_data.csv"}>
                <button className="text-[#3EB211] font-poppins font-medium">
                  Export CSV
                </button>
              </CSVLink>
            </div>
            <div className="flex flex-col items-center gap-10 text-[0.875rem] mt-[24px]">
              <div className="flex items-center justify-between">
                <div className="flex items-center w-[50%] border-b border-b-[#1D1D1D] px-2 pb-2">
                  <img src={Search} alt="" />
                  <input
                    onChange={(e) => setSearchTerm(e.target.value)}
                    value={searchTerm}
                    type="text"
                    placeholder="Search"
                    className="px-3 outline-none font-poppins"
                  />
                </div>
                {/* <button className="border border-[#E4E4E4] flex font-medium items-center font-poppins px-2 py-1 rounded-[8px] gap-[8px]">
                  Filter
                  <img src={Chevron} alt="" />
                </button> */}
              </div>
              <div className="flex items-center gap-5 font-poppins font-medium">
                {prevUrl && (
                  <button
                    onClick={() => {
                      setNewUrl(prevUrl);
                    }}
                    className="bg-white text-[#3EB211] border border-[#3EB211] py-2 px-4 rounded-lg"
                  >
                    Prev
                  </button>
                )}
                {nextUrl && (
                  <div>
                    <p>
                      Page {nextUrl?.split("=")[1] - 1} of{" "}
                      {Math.round(total / 50)}
                    </p>
                  </div>
                )}
                {nextUrl && (
                  <button
                    onClick={() => {
                      setCount(count + 1);
                      setNewUrl(nextUrl);
                    }}
                    className="bg-[#3EB211] border border-[#3EB211] text-white rounded-lg py-2 px-4"
                  >
                    Next
                  </button>
                )}
              </div>
            </div>
            {loading ? (
              <div className="w-[100%] h-[50vh] flex items-center justify-center">
                <Triangle
                  visible={true}
                  height="80"
                  width="80"
                  color="#3EB211"
                  ariaLabel="triangle-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              </div>
            ) : (
              <DiscountsTable
                data={data}
                setSelectedDiscount={setSelectedDiscount}
                setOpenSelected={setOpenSelected}
              />
            )}
          </div>
        </div>
      </div>
      <Dialog
        open={openCreate}
        onClose={() => {
          setOpenCreate(false);
        }}
        className="relative z-50"
      >
        <div className="fixed inset-0 flex w-screen items-center bg-black/50 justify-center p-4">
          <DialogPanel className="rounded-lg font-poppins space-y-6 border bg-white p-6 w-[600px] lg:max-h-[700px] max-h-[600px] overflow-y-scroll">
            <div className="flex items-center justify-start">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                className="cursor-pointer"
                onClick={() => {
                  setOpenCreate(false);
                }}
              >
                <path
                  d="M2.42371 7.52876C2.29873 7.65378 2.22852 7.82332 2.22852 8.0001C2.22852 8.17687 2.29873 8.34641 2.42371 8.47143L6.19504 12.2428C6.25654 12.3064 6.3301 12.3572 6.41144 12.3922C6.49277 12.4271 6.58025 12.4455 6.66877 12.4463C6.75729 12.447 6.84508 12.4302 6.92701 12.3966C7.00894 12.3631 7.08337 12.3136 7.14597 12.251C7.20856 12.1884 7.25807 12.114 7.29159 12.0321C7.32511 11.9501 7.34198 11.8623 7.34121 11.7738C7.34044 11.6853 7.32205 11.5978 7.28711 11.5165C7.25217 11.4352 7.20138 11.3616 7.13771 11.3001L4.50437 8.66676L13.333 8.66676C13.5099 8.66676 13.6794 8.59653 13.8044 8.4715C13.9295 8.34648 13.9997 8.17691 13.9997 8.0001C13.9997 7.82329 13.9295 7.65372 13.8044 7.52869C13.6794 7.40367 13.5099 7.33343 13.333 7.33343L4.50437 7.33343L7.13771 4.7001C7.25915 4.57436 7.32634 4.40596 7.32482 4.23116C7.3233 4.05637 7.25319 3.88916 7.12959 3.76555C7.00598 3.64195 6.83877 3.57183 6.66397 3.57031C6.48918 3.56879 6.32078 3.63599 6.19504 3.75743L2.42371 7.52876Z"
                  fill="#333333"
                />
              </svg>
            </div>
            <div className="flex items-center flex-col justify-center border-b border-b-[#E4E4E4] pb-6">
              <p className="text-center text-[1.125rem] font-medium text-[#333333] mb-2">
                Create Discount Code
              </p>
            </div>
            <div className="space-y-6">
              <div className="flex flex-col">
                <label
                  className="font-poppins text-[0.75rem] mb-2 text-[#62646A99] font-medium"
                  htmlFor=""
                >
                  Code (required)
                </label>
                <input
                  type="text"
                  value={discountDetails.code}
                  onChange={(e) =>
                    setDiscountDetails({
                      ...discountDetails,
                      code: e.target.value.toUpperCase(),
                    })
                  }
                  className="border border-[#333333] py-1 px-3 outline-none #1D1D1D rounded"
                />
              </div>

              <div className="flex flex-col">
                <label
                  className="font-poppins text-[0.75rem] mb-2 text-[#62646A99] font-medium"
                  htmlFor=""
                >
                  Discount Percentage (required)
                </label>
                <input
                  type="text"
                  value={discountDetails.discount_percentage}
                  onChange={(e) =>
                    setDiscountDetails({
                      ...discountDetails,
                      discount_percentage: e.target.value,
                    })
                  }
                  className="border border-[#333333] py-1 px-3 outline-none #1D1D1D rounded"
                />
              </div>
              <div className="flex flex-col">
                <label
                  className="font-poppins text-[0.75rem] mb-2 text-[#62646A99] font-medium"
                  htmlFor=""
                >
                  Type (required)
                </label>
                <select
                  value={discountDetails.type}
                  onChange={(e) =>
                    setDiscountDetails({
                      ...discountDetails,
                      type: e.target.value,
                    })
                  }
                  name=""
                  id=""
                  className="border border-[#333333] py-1 px-3 outline-none #1D1D1D rounded"
                >
                  <option value="general">General</option>
                  <option value="personal">Personal</option>
                  {/* <option value="personal-50">Personal 50</option> */}
                  <option value="account-size">Account Size</option>
                </select>
              </div>
              <div className="flex flex-col">
                <label
                  className="font-poppins text-[0.75rem] mb-2 text-[#62646A99] font-medium"
                  htmlFor=""
                >
                  Expiry Date (required)
                </label>
                <input
                  type="date"
                  value={discountDetails.expiry_date}
                  onChange={(e) =>
                    setDiscountDetails({
                      ...discountDetails,
                      expiry_date: e.target.value,
                    })
                  }
                  className="border border-[#333333] py-1 px-3 outline-none #1D1D1D rounded"
                />
              </div>
              <div className="flex flex-col">
                <label
                  className="font-poppins text-[0.75rem] mb-2 text-[#62646A99] font-medium"
                  htmlFor=""
                >
                  User Email
                </label>
                <input
                  type="email"
                  value={discountDetails.user_email}
                  onChange={(e) =>
                    setDiscountDetails({
                      ...discountDetails,
                      user_email: e.target.value,
                    })
                  }
                  className="border border-[#333333] py-1 px-3 outline-none #1D1D1D rounded"
                />
              </div>
              <div className="flex flex-col">
                <label
                  className="font-poppins text-[0.75rem] mb-2 text-[#62646A99] font-medium"
                  htmlFor=""
                >
                  Max Number of Accounts
                </label>
                <input
                  type="text"
                  value={discountDetails.num_of_accounts}
                  onChange={(e) =>
                    setDiscountDetails({
                      ...discountDetails,
                      num_of_accounts: e.target.value,
                    })
                  }
                  className="border border-[#333333] py-1 px-3 outline-none #1D1D1D rounded"
                />
              </div>
              <div className="flex flex-col">
                <label
                  className="font-poppins text-[0.75rem] mb-2 text-[#62646A99] font-medium"
                  htmlFor=""
                >
                  Account Size
                </label>
                <select
                  value={discountDetails.acc_size}
                  onChange={(e) =>
                    setDiscountDetails({
                      ...discountDetails,
                      acc_size: e.target.value,
                    })
                  }
                  name=""
                  id=""
                  className="border border-[#333333] py-1 px-3 outline-none #1D1D1D rounded"
                >
                  <option value=""></option>
                  <option value="1000">1000</option>
                  <option value="3000">3000</option>
                  <option value="5000">5000</option>
                  <option value="10000">10000</option>
                  <option value="25000">25000</option>
                  <option value="50000">50000</option>
                </select>
              </div>
              <div className="flex flex-col">
                <label
                  className="font-poppins text-[0.75rem] mb-2 text-[#62646A99] font-medium"
                  htmlFor=""
                >
                  Account Model
                </label>
                <select
                  value={discountDetails.acc_model}
                  onChange={(e) =>
                    setDiscountDetails({
                      ...discountDetails,
                      acc_model: e.target.value,
                    })
                  }
                  name=""
                  id=""
                  className="border border-[#333333] py-1 px-3 outline-none #1D1D1D rounded"
                >
                  <option value=""></option>
                  <option value="challenge">Challenge</option>
                  <option value="classic">Classic</option>
                </select>
              </div>
              <div className="flex flex-col">
                <label
                  className="font-poppins text-[0.75rem] mb-2 text-[#62646A99] font-medium"
                  htmlFor=""
                >
                  Description
                </label>
                <textarea
                  onChange={(e) =>
                    setDiscountDetails({
                      ...discountDetails,
                      description: e.target.value,
                    })
                  }
                  value={discountDetails.description}
                  className="border border-[#333333] py-1 px-3 outline-none #1D1D1D rounded"
                  name=""
                  id=""
                ></textarea>
              </div>
            </div>

            <button
              onClick={createDiscount}
              className="bg-[#3EB211] text-white rounded-md py-3 w-[100%]"
            >
              {createLoading ? "Please wait..." : "Save"}
            </button>
            <button
              onClick={() => {
                setDiscountDetails({
                  code: "",
                  discount_percentage: "",
                  type: "general",
                  expiry_date: "",
                  user_email: "",
                  is_active: true,
                  description: "",
                  num_of_accounts: "",
                  acc_size: "",
                  acc_model: "",
                });
                setOpenCreate(false);
              }}
              className={`${"border border-[#3EB211] text-[#3EB211] bg-white"} rounded-md py-3 w-[100%]`}
            >
              Cancel
            </button>
          </DialogPanel>
        </div>
      </Dialog>
      <Dialog
        open={openSelected}
        onClose={() => {
          setOpenSelected(false);
        }}
        className="relative z-50"
      >
        <div className="fixed inset-0 flex w-screen items-center bg-black/50 justify-center p-4">
          <DialogPanel className="rounded-lg font-poppins space-y-6 border bg-white p-6 w-[600px] lg:max-h-[700px] max-h-[600px] overflow-y-scroll">
            <div className="flex items-center justify-between">
              <CSVLink data={CSVModaldata} filename={"fca_data.csv"}>
                <button className="text-[#3EB211] font-poppins font-medium">
                  Export CSV
                </button>
              </CSVLink>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="17"
                viewBox="0 0 17 17"
                fill="none"
                className="cursor-pointer"
                onClick={() => {
                  setOpenSelected(false);
                }}
              >
                <path
                  d="M12.7001 3.97283C12.6385 3.91103 12.5652 3.862 12.4846 3.82854C12.4039 3.79509 12.3175 3.77787 12.2301 3.77787C12.1428 3.77787 12.0564 3.79509 11.9757 3.82854C11.8951 3.862 11.8218 3.91103 11.7601 3.97283L8.50015 7.22616L5.24015 3.96616C5.17843 3.90444 5.10515 3.85548 5.02451 3.82208C4.94387 3.78868 4.85744 3.77148 4.77015 3.77148C4.68286 3.77148 4.59643 3.78868 4.51579 3.82208C4.43514 3.85548 4.36187 3.90444 4.30015 3.96616C4.23843 4.02789 4.18947 4.10116 4.15606 4.1818C4.12266 4.26245 4.10547 4.34888 4.10547 4.43616C4.10547 4.52345 4.12266 4.60988 4.15606 4.69053C4.18947 4.77117 4.23843 4.84444 4.30015 4.90616L7.56015 8.16616L4.30015 11.4262C4.23843 11.4879 4.18947 11.5612 4.15606 11.6418C4.12266 11.7224 4.10547 11.8089 4.10547 11.8962C4.10547 11.9835 4.12266 12.0699 4.15606 12.1505C4.18947 12.2312 4.23843 12.3044 4.30015 12.3662C4.36187 12.4279 4.43514 12.4768 4.51579 12.5102C4.59643 12.5437 4.68286 12.5608 4.77015 12.5608C4.85744 12.5608 4.94387 12.5437 5.02451 12.5102C5.10515 12.4768 5.17843 12.4279 5.24015 12.3662L8.50015 9.10616L11.7601 12.3662C11.8219 12.4279 11.8951 12.4768 11.9758 12.5102C12.0564 12.5437 12.1429 12.5608 12.2301 12.5608C12.3174 12.5608 12.4039 12.5437 12.4845 12.5102C12.5652 12.4768 12.6384 12.4279 12.7001 12.3662C12.7619 12.3044 12.8108 12.2312 12.8442 12.1505C12.8776 12.0699 12.8948 11.9835 12.8948 11.8962C12.8948 11.8089 12.8776 11.7224 12.8442 11.6418C12.8108 11.5612 12.7619 11.4879 12.7001 11.4262L9.44015 8.16616L12.7001 4.90616C12.9535 4.65283 12.9535 4.22616 12.7001 3.97283Z"
                  fill="#333333"
                />
              </svg>
            </div>
            <div className="flex items-center flex-col justify-center border-b border-b-[#E4E4E4] pb-6">
              <p className="text-center text-[1.125rem] font-medium text-[#333333] mb-2">
                Diacount Details
              </p>
            </div>
            <div className="space-y-6 border border-[#E4E4E4] rounded-lg p-6">
              <div className="w-full space-y-6">
                <div className="flex items-center justify-between flex-wrap gap-6 w-[100%]">
                  <div>
                    <p className="text-[0.75rem] text-[#62646A99] mb-1">CODE</p>
                    <p className="text-[0.875rem] text-[#333333] font-medium">
                      {" "}
                      {selectedDiscount?.code}
                    </p>
                  </div>
                  <div>
                    <p className="text-[0.75rem] text-[#62646A99] mb-1">
                      EXPIRY DATE
                    </p>
                    <p className="text-[0.875rem] text-[#333333] font-medium">
                      {" "}
                      {convertDate(selectedDiscount?.expiry_date)}
                    </p>
                  </div>
                </div>
                <div className="flex items-center justify-between flex-wrap gap-6 w-[100%]">
                  <div>
                    <p className="text-[0.75rem] text-[#62646A99] mb-1">
                      DISCOUNT %
                    </p>
                    <p className="text-[0.875rem] text-[#333333] font-medium">
                      {selectedDiscount?.discount_percentage}
                    </p>
                  </div>
                  <div>
                    <p className="text-[0.75rem] text-[#62646A99] mb-1">
                      CREATED ON
                    </p>
                    <p className="text-[0.875rem] text-[#333333] font-medium">
                      {" "}
                      {convertDate(selectedDiscount?.created)}
                    </p>
                  </div>
                </div>
                <div className="flex items-center justify-between w-[100%]">
                  <div>
                    <p className="text-[0.75rem] text-[#62646A99] mb-1">TYPE</p>
                    <p className="text-[0.875rem] text-[#333333] font-medium">
                      {selectedDiscount?.type}
                    </p>
                  </div>
                  <div>
                    <p className="text-[0.75rem] text-[#62646A99] mb-1">
                      USER EMAIL
                    </p>
                    <p className="text-[0.875rem] text-[#333333] font-medium">
                      {selectedDiscount?.user || "No Email"}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <button
              // disabled={selectedDiscount?.blown}
              onClick={deleteDiscount}
              className="bg-[#B41007] text-white rounded-md py-3 w-[100%] disabled:bg-gray-400 disabled:text-gray-900"
            >
              {createLoading ? "Please wait..." : "Delete"}
            </button>
          </DialogPanel>
        </div>
      </Dialog>
    </div>
  );
}

export default Discounts;
