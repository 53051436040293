import React from "react";
import Sorting from "../assets/f7_sort-up (1).svg";
import More from "../assets/uiw_more (1).svg";

function AccountsTable({ data }) {
  return (
    <div className="w-[100%] mt-[24px]">
      <table className="w-[100%] hidden lg:block">
        <thead className="bg-[#F1F1F1]">
          <tr>
            <td className="pl-[16px] py-[8px] text-[#62646A99] font-poppins lg:w-[16.6%]">
              {" "}
              <div className="flex items-center gap-[8px]">
                Username
                <img src={Sorting} alt="" />
              </div>
            </td>
            <td className="py-[8px] text-[#62646A99] font-poppins lg:w-[16.6%]">
              {" "}
              <div className="flex items-center justify-center gap-[8px]">
                User ID
                <img src={Sorting} alt="" />
              </div>
            </td>
            <td className="py-[8px] text-[#62646A99] font-poppins lg:w-[16.6%]">
              {" "}
              <div className="flex items-center gap-[8px]">
                Email Address
                <img src={Sorting} alt="" />
              </div>
            </td>
            <td className="py-[8px] text-[#62646A99] font-poppins lg:w-[16.6%]">
              {" "}
              <div className="flex items-center justify-center gap-[8px]">
                Registration Date
                <img src={Sorting} alt="" />
              </div>
            </td>
            <td className="py-[8px] text-[#62646A99] font-poppins lg:w-[16.6%] text-center">
              Status
            </td>
            <td className="py-[8px] text-[#62646A99] font-poppins lg:w-[16.6%]"></td>
          </tr>
        </thead>
        <tbody>
          {data?.map((da) => (
            <tr key={da._id}>
              <td className="pl-[16px] py-[16px] text-[#333333] font-medium font-poppins">
                {da.firstName} {da.lastName}
              </td>
              <td className="py-[16px] text-[#333333] font-medium font-poppins text-center">
                {da._id.slice(-5)}
              </td>
              <td className="py-[16px] text-[#333333] font-medium font-poppins">
                {da.email}
              </td>
              <td className="py-[16px] text-[#333333] text-center font-medium font-poppins">
                {/* {convertDate(da.createdAt)} */}
              </td>
              <td className="py-[16px] text-[#333333] font-medium font-poppins text-center">
                Active
              </td>
              <td className="text-[#333333] font-medium font-poppins">
                <div
                  // onClick={() => {
                  //   setSelected(da);
                  //   setIsOpen(true);
                  // }}
                  className="py-[16px] flex items-center justify-center cursor-pointer"
                >
                  <img src={More} alt="" />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="lg:hidden flex flex-col gap-[16px] w-[100%]">
        {data.map((da) => (
          <div
            key={da._id}
            className="flex flex-col border border-[#C8C8C8] rounded-lg p-4 space-y-6 font-poppins"
          >
            <div
              // onClick={() => {
              //   setSelected(da);
              //   setIsOpen(true);
              // }}
              className="flex justify-end cursor-pointer"
            >
              <img src={More} alt="" />
            </div>
            <div className="flex items-center justify-between flex-wrap gap-6">
              <div>
                <p className="text-[0.75rem] text-[#62646A99] mb-1">USERNAME</p>
                <p className="text-[0.875rem] text-[#333333] font-medium">
                  {" "}
                  {da.firstName} {da.lastName}
                </p>
              </div>
              <div>
                <p className="text-[0.75rem] text-[#62646A99] mb-1">USER ID</p>
                <p className="text-[0.875rem] text-[#333333] font-medium">
                  {da._id.slice(-5)}
                </p>
              </div>
            </div>
            <div className="flex items-center justify-between flex-wrap gap-6">
              <div>
                <p className="text-[0.75rem] text-[#62646A99] mb-1">
                  EMAIL ADDRESS
                </p>
                <p className="text-[0.875rem] text-[#333333] font-medium">
                  {" "}
                  {da.email}
                </p>
              </div>
              <div>
                <p className="text-[0.75rem] text-[#62646A99] mb-1">
                  REGISTRATION DATE
                </p>
                <p className="text-[0.875rem] text-[#333333] font-medium">
                  {/* {convertDate(da.createdAt)} */}
                </p>
              </div>
            </div>
            <div className="flex items-center justify-between">
              <div>
                <p className="text-[0.75rem] text-[#62646A99] mb-1">STATUS</p>
                <p className="bg-[#72E52C1A] text-[#72E52C] text-[0.875rem] font-medium rounded-[200px] px-2 py-1">
                  ACTIVE
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default AccountsTable;
