import React from "react";
import Sorting from "../assets/f7_sort-up (1).svg";
import More from "../assets/uiw_more (1).svg";
import truncateText from "../../utils/TruncateText";

function ChatbotFeedTable({ data, setSelectedUser, setOpen }) {
  return (
    <div className="w-[100%] mt-[24px]">
      <table className="w-[100%] hidden lg:table">
        <thead className="bg-[#F1F1F1] text-[0.875rem]">
          <tr>
            <td className="pl-[16px] py-[8px] text-[#62646A99] font-poppins lg:w-[16.7%]">
              {" "}
              <div className="flex items-center gap-[8px]">
                User
                <img src={Sorting} alt="" />
              </div>
            </td>
            <td className="py-[8px] text-[#62646A99] font-poppins lg:w-[16.7%]">
              {" "}
              <div className="flex items-center justify-center gap-[8px]">
                Rated Message
                <img src={Sorting} alt="" />
              </div>
            </td>
            <td className="py-[8px] text-[#62646A99] font-poppins lg:w-[20.7%]">
              {" "}
              <div className="flex items-center justify-center gap-[8px]">
                Preceding User Message
                <img src={Sorting} alt="" />
              </div>
            </td>
            <td className="py-[8px] text-[#62646A99] font-poppins lg:w-[16.7%]">
              {" "}
              <div className="flex items-center justify-center gap-[8px]">
                Vote
                <img src={Sorting} alt="" />
              </div>
            </td>
            <td className="py-[8px] text-[#62646A99] font-poppins lg:w-[16.7%] text-center">
              {" "}
              <div className="flex items-center justify-center gap-[8px]">
                Feedback
                <img src={Sorting} alt="" />
              </div>
            </td>
            <td className="py-[8px] text-[#62646A99] font-poppins lg:w-[12.7%]"></td>
          </tr>
        </thead>
        <tbody className="text-[0.875rem]">
          {data?.map((da) => (
            <tr key={da.id}>
              <td className="pl-[16px] py-[16px] text-[#333333] font-medium font-poppins">
                {da.name || "No name"}{" "}
                <p className="text-[#62646A99] text-[0.75rem] font-medium">
                  {da.email || "No email"}
                </p>
              </td>
              <td className="py-[16px] text-[#333333] font-medium font-poppins text-center">
                {truncateText(da.rated_message?.content, 40) || "No message"}
              </td>
              <td className="py-[16px] text-[#333333] font-medium font-poppins text-center">
                {truncateText(da.preceding_user_message?.content, 40) ||
                  "No message"}
              </td>

              <td className="py-[16px] font-medium font-poppins flex items-center justify-center">
                <p
                  className={`${
                    da.vote === "upvote"
                      ? "bg-[#72E52C1A] text-[#72E52C]"
                      : "bg-[#B410071A] text-[#B41007]"
                  } text-[0.875rem] font-medium rounded-[200px] px-2 w-max text-center py-1`}
                >
                  {da.vote || "No vote"}
                </p>
              </td>
              <td className="py-[16px] text-[#333333] text-center font-medium font-poppins">
                {da.feedback || "No feedback"}
              </td>
              <td className="text-[#333333] font-medium font-poppins">
                <div
                  onClick={() => {
                    setSelectedUser(da);
                    setOpen(true);
                  }}
                  className="py-[16px] flex items-center justify-center cursor-pointer"
                >
                  <img src={More} alt="" />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="lg:hidden flex flex-col gap-[16px] w-[100%]">
        {data.map((da) => (
          <div
            key={da._id}
            className="flex flex-col border border-[#C8C8C8] rounded-lg p-4 space-y-6 font-poppins"
          >
            <div
              onClick={() => {
                setSelectedUser(da);
                setOpen(true);
              }}
              className="flex justify-end cursor-pointer"
            >
              <img src={More} alt="" />
            </div>
            <div className="flex items-center justify-between flex-wrap gap-6">
              <div>
                <p className="text-[0.75rem] text-[#62646A99] mb-1">USER</p>
                <p className="text-[0.875rem] text-[#333333] font-medium">
                  {" "}
                  {da.name || "No name"}
                  <p className="text-[#62646A99] text-[0.75rem] font-medium">
                    {da.email || "No email"}
                  </p>
                </p>
              </div>
              <div>
                <p className="text-[0.75rem] text-[#62646A99] mb-1">
                  RATED MESSAGE
                </p>
                <p className="text-[0.875rem] text-[#333333] font-medium">
                  {truncateText(da.rated_message?.content, 20) || "No message"}
                </p>
              </div>
            </div>
            <div className="flex items-center justify-between flex-wrap gap-6">
              <div>
                <p className="text-[0.75rem] text-[#62646A99] mb-1">
                  PRECEDING USER MESSAGE
                </p>
                <p className="text-[0.875rem] text-[#333333] font-medium">
                  {" "}
                  {truncateText(da.preceding_user_message?.content, 20) ||
                    "No message"}
                </p>
              </div>
              <div>
                <p className="text-[0.75rem] text-[#62646A99] mb-1">VOTE</p>
                <p
                  className={`${
                    da.vote === "upvote"
                      ? "bg-[#72E52C1A] text-[#72E52C]"
                      : "bg-[#B410071A] text-[#B41007]"
                  } text-[0.875rem] font-medium rounded-[200px] px-2 py-1`}
                >
                  {da.vote || "No vote"}
                </p>
              </div>
            </div>
            <div className="flex items-center justify-between">
              <div>
                <p className="text-[0.75rem] text-[#62646A99] mb-1">FEEDBACK</p>
                <p className="text-[0.875rem] text-[#333333] font-medium text-center">
                  {da.feedback || "No feedback"}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ChatbotFeedTable;
