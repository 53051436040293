/* eslint-disable no-unused-vars */
import React, { Fragment, useEffect, useState } from "react";
import Sidebar from "../components/Sidebar";
import Topbar from "../components/Topbar";
import MainLineChart from "../components/Charts/MainLineChart";
import Chevron from "../components/assets/ep_arrow-up-bold.svg";
import {
  Dialog,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
} from "@headlessui/react";
import { Triangle } from "react-loader-spinner";
import MainBarChart from "../components/Charts/MainBarChart";
import UsersBlack from "../components/assets/heroicons_user-20-solid (3).svg";
import AccountsBlack from "../components/assets/mdi_briefcase-account (1).svg";
import ReceiptBlack from "../components/assets/ri_receipt-fill (1).svg";
import PayoutsBlack from "../components/assets/fluent_payment-28-filled (1).svg";
import DiscountsBlack from "../components/assets/teenyicons_discount-solid (1).svg";
import ViolationsBlack from "../components/assets/tabler_message-report-filled.svg";
import axiosInstance from "../axios";

function convertDate(isoDate) {
  //   const isoDate = "2023-06-23T20:37:03.658Z";

  // Create a new Date object from the ISO string
  const date = new Date(isoDate);

  // Extract day, month, and year
  const day = String(date.getDate()).padStart(2, "0");
  const month = date.toLocaleString("default", { month: "long" });
  const year = date.getFullYear();

  // Format the date as "dd mm, yyyy"
  const formattedDate = `${day} ${month}, ${year}`;

  return formattedDate; // Output: 23 June, 2023
}

function Analytics() {
  const [selectedTime, setSelectedTime] = useState("last_1week");
  const [graphSelectedTime, setGraphSelectedTime] = useState("last_week_data");
  const [bottomTime, setBottomTime] = useState("last_month");
  const [loading, setLoading] = useState(false);
  const [graphLoading, setGraphLoading] = useState(false);
  const [stats, setStats] = useState([]);
  const [newStats, setNewStats] = useState({});
  const [graphData, setGraphData] = useState({});
  const [selectedGraphData, setSelectedGraphData] = useState([]);
  const [filter1, setFilter1] = useState("registered_users");
  const [filter2, setFilter2] = useState("trade_accounts");
  const [selected, setSelected] = useState(null);
  const [selectedName, setSelectedName] = useState("");
  const [barChartData, setBarChartData] = useState({});
  const [barChartFilter, setBarChartFilter] = useState("account_size_data");
  const [bottomStats, setBottomStats] = useState([]);
  const [group, setGroup] = useState([]);
  const [email, setEmail] = useState("");
  const [avgVioTime, setAvgVioTime] = useState()

  console.log(newStats);
  console.log(selectedName);

  useEffect(() => {
    axiosInstance
      .get("/dashboard/user-groups/")
      .then((res) => {
        console.log(res);
        localStorage.setItem("email", res.data.user);
        localStorage.setItem("groups", JSON.stringify(res.data.groups));
        setGroup(res.data.groups);
        setEmail(res.data.user);
        // setDetails({ email: "", password: "" });
        // setLoading(false);
        // navigate("/home");
      })
      .catch((err) => {
        console.log(err);
        // setLoading(false);
      });
  }, []);

  // console.log(selectedGraphData);

  // console.log(stats)

  useEffect(() => {
    const getLearnings = async () => {
      setLoading(true);
      try {
        const res = await axiosInstance.get("/dashboard/barchart/");
        console.log(res.data);
        setLoading(false);
        setBarChartData(res.data);
        // setStats(res.data);
      } catch (error) {
        console.log(error);
      }
    };
    const getGraph = async () => {
      setGraphLoading(true);
      try {
        const res = await axiosInstance.get("/dashboard/analytics/");
        console.log(res);
        setGraphLoading(false);
        setNewStats(res.data);
      } catch (error) {
        console.log(error);
      }
    };
    const getNewAnalyticsGraph = async () => {
      setGraphLoading(true);
      try {
        const res = await axiosInstance.get("/dashboard/analytics2/");
        console.log(res);
        setGraphLoading(false);
        setBottomStats(res.data);
      } catch (error) {
        console.log(error);
      }
    };
    const getNewGraph = async () => {
      setGraphLoading(true);
      try {
        const res = await axiosInstance.get("/dashboard/graph/");
        console.log(res);
        setGraphLoading(false);
        setGraphData(res.data);
      } catch (error) {
        console.log(error);
      }
    };
    const getNewAvgVioTime = async () => {
      setGraphLoading(true);
      try {
        const res = await axiosInstance.get("/dashboard/avg-violation/");
        console.log(res);
        setGraphLoading(false);
        setAvgVioTime(res.data);
      } catch (error) {
        console.log(error);
      }
    };
    getGraph();
    getNewGraph();
    getLearnings();
    getNewAnalyticsGraph();
    getNewAvgVioTime()
  }, []);

  useEffect(() => {
    const hasReloaded = sessionStorage.getItem("hasReloaded");
    if (!hasReloaded) {
      sessionStorage.setItem("hasReloaded", "true");
      window.location.reload();
    }
  }, []);

  useEffect(() => {
    setSelectedGraphData(graphData[graphSelectedTime]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [graphSelectedTime, graphData]);

  return (
    <div className="flex h-[100vh] font-poppins">
      <Sidebar />
      <div className="lg:w-[82%] w-[100%]">
        <Topbar />

        {group?.includes("Super Admin") ||
        group?.includes("View-only Super Admin") ? (
          <div className="lg:p-[32px] p-[16px] h-[88vh] overflow-y-scroll">
            <div className="flex items-center justify-between mb-4 font-poppins">
              <p className="text-[#1E1E1E] font-medium text-[0.875rem]">
                Overview
              </p>
              {/* <p className="text-[#3EB211] font-medium text-[0.875rem]">
              Export CSV
            </p> */}
            </div>

            {loading || !barChartData?.broker_data ? (
              <div className="w-[100%] h-[50vh] flex items-center justify-center">
                <Triangle
                  visible={true}
                  height="80"
                  width="80"
                  color="#3EB211"
                  ariaLabel="triangle-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              </div>
            ) : (
              <div className="mt-10 border border-[#D4D4D4] p-6 rounded-[8px]">
                <Menu>
                  <MenuButton className="flex gap-3 border border-[#D8D8D8] p-2 rounded-lg w-max mb-6 font-medium text-[#1E1E1E] text-[0.75rem]">
                    {barChartFilter === "account_size_data"
                      ? "ACCOUNTS SIZES"
                      : barChartFilter === "model_data"
                      ? "MODELS"
                      : "BROKERS"}
                    <img src={Chevron} alt="" />
                  </MenuButton>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <MenuItems
                      as="div"
                      className="absolute w-[11.2rem] z-10 lg:mt-[1rem] mt-[0.1rem] lg:mr-[5rem] origin-top-right divide-y divide-gray-300 rounded-[0.75rem] border border-[#D8D8D8] ring-1 ring-black ring-opacity-5 focus:outline-none flex flex-col"
                    >
                      <MenuItem
                        onClick={() => setBarChartFilter("account_size_data")}
                        as="div"
                        className="hover:bg-[#4E1EE3] rounded-t-[0.75rem] cursor-pointer bg-white hover:text-white p-2 font-poppins font-medium text-[#333333] lg:text-[0.875rem] text-[0.75rem]"
                      >
                        ACCOUNTS SIZES
                      </MenuItem>
                      <MenuItem
                        onClick={() => setBarChartFilter("broker_data")}
                        as="div"
                        className="hover:bg-[#4E1EE3] bg-white cursor-pointer hover:text-white p-2 font-poppins font-medium text-[#333333] lg:text-[0.875rem] text-[0.75rem]"
                      >
                        BROKERS
                      </MenuItem>
                      <MenuItem
                        onClick={() => setBarChartFilter("model_data")}
                        as="div"
                        className="hover:bg-[#4E1EE3] rounded-b-[0.75rem] bg-white cursor-pointer hover:text-white p-2 font-poppins font-medium text-[#333333] lg:text-[0.875rem] text-[0.75rem]"
                      >
                        MODELS
                      </MenuItem>
                    </MenuItems>
                  </Transition>
                </Menu>
                <MainBarChart data={barChartData[barChartFilter]} />
              </div>
            )}

            {loading && newStats.last_1week ? (
              <div className="w-[100%] h-[50vh] flex items-center justify-center">
                <Triangle
                  visible={true}
                  height="80"
                  width="80"
                  color="#3EB211"
                  ariaLabel="triangle-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              </div>
            ) : (
              <div className="mt-10">
                <div className="flex justify-end mb-4">
                  <div className="flex items-center border border-[#E4E4E4] rounded-[8px] font-poppins text-[0.75rem] p-[2px]">
                    <p
                      onClick={() => setSelectedTime("last_1week")}
                      className={`py-2 cursor-pointer px-4 ${
                        selectedTime === "last_1week" &&
                        "bg-[#F1F1F1] border border-[#E4E4E4] rounded-[8px]"
                      }`}
                    >
                      1 W
                    </p>
                    <p
                      onClick={() => setSelectedTime("last_1month")}
                      className={`py-2 cursor-pointer px-4 ${
                        selectedTime === "last_1month" &&
                        "bg-[#F1F1F1] border border-[#E4E4E4] rounded-[8px]"
                      }`}
                    >
                      1 M
                    </p>
                    <p
                      onClick={() => setSelectedTime("last_6months")}
                      className={`py-2 cursor-pointer px-4 ${
                        selectedTime === "last_6months" &&
                        "bg-[#F1F1F1] border border-[#E4E4E4] rounded-[8px]"
                      }`}
                    >
                      6 M
                    </p>
                    <p
                      onClick={() => setSelectedTime("last_1year")}
                      className={`py-2 cursor-pointer px-4 ${
                        selectedTime === "last_1year" &&
                        "bg-[#F1F1F1] border border-[#E4E4E4] rounded-[8px]"
                      }`}
                    >
                      1 YR
                    </p>
                    <p
                      onClick={() => setSelectedTime("total")}
                      className={`py-2 cursor-pointer px-4 ${
                        selectedTime === "total" &&
                        "bg-[#F1F1F1] border border-[#E4E4E4] rounded-[8px]"
                      }`}
                    >
                      ALL
                    </p>
                  </div>
                </div>
                <div className="grid lg:grid-cols-2 gap-4 font-poppins">
                  <div className="border border-[#E4E4E4] bg-[#E4E4E4] rounded-[8px] py-10 pl-6 flex items-center gap-6">
                    <img
                      src={UsersBlack}
                      alt=""
                      className="p-4 bg-[#62646A4D] rounded-full"
                      // onClick={() => {
                      //   setSelectedName("user");
                      //   selectedTime === "1w" &&
                      //     setSelected(stats?.oneWeekDoc[0]);
                      //   selectedTime === "1m" &&
                      //     setSelected(stats?.oneMonthDoc[0]);
                      //   selectedTime === "6m" &&
                      //     setSelected(stats?.sixMonthsDoc[0]);
                      //   selectedTime === "1yr" &&
                      //     setSelected(stats?.oneYearDoc[0]);
                      // }}
                    />
                    <div className="flex flex-col gap-2">
                      <p className="font-medium text-[0.75rem] text-[#333333]">
                        TOTAL NO. OF USERS
                      </p>
                      <p className="font-semibold text-3xl text-[#333333]">
                        {newStats[selectedTime]?.total_users.toLocaleString()}
                      </p>
                      <p className="text-[#1E1E1EB2] font-medium text-[0.75rem]">
                        {selectedTime !== "total" && (
                          <span
                            className={`${
                              newStats[selectedTime]?.total_users_change < 1
                                ? "text-[#B41007] bg-[#B410071A]"
                                : "text-[#3EB211] bg-[#6EDA441A]"
                            } font-medium text-[0.875rem] rounded-[100px] py-[2px] px-2`}
                          >
                            {newStats[selectedTime]?.total_users_change}%
                          </span>
                        )}{" "}
                        {selectedTime === "last_1week" && "Last One Week"}
                        {selectedTime === "last_1month" && "Last One Month"}
                        {selectedTime === "last_6months" && "Last Six Months"}
                        {selectedTime === "last_1year" && "Last One Year"}
                      </p>
                    </div>
                  </div>
                  <div className="border border-[#E4E4E4] bg-[#E4E4E4] rounded-[8px] py-10 pl-6 flex items-center gap-6">
                    <img
                      src={AccountsBlack}
                      alt=""
                      className="p-4 bg-[#62646A4D] rounded-full"
                      // onClick={() => {
                      //   setSelectedName("quiz");
                      //   selectedTime === "1w" &&
                      //     setSelected(stats?.oneWeekDoc[1]);
                      //   selectedTime === "1m" &&
                      //     setSelected(stats?.oneMonthDoc[1]);
                      //   selectedTime === "6m" &&
                      //     setSelected(stats?.sixMonthsDoc[1]);
                      //   selectedTime === "1yr" &&
                      //     setSelected(stats?.oneYearDoc[1]);
                      // }}
                    />
                    <div className="flex flex-col gap-2">
                      <p className="font-medium text-[0.75rem] text-[#333333]">
                        TOTAL NO. OF ACCOUNTS
                      </p>
                      <p className="font-semibold text-3xl text-[#333333]">
                        {newStats[
                          selectedTime
                        ]?.total_accounts.toLocaleString()}
                      </p>
                      <p className="text-[#1E1E1EB2] font-medium text-[0.75rem]">
                        {selectedTime !== "total" && (
                          <span
                            className={`${
                              newStats[selectedTime]?.total_accounts_change < 1
                                ? "text-[#B41007] bg-[#B410071A]"
                                : "text-[#3EB211] bg-[#6EDA441A]"
                            } font-medium text-[0.875rem] rounded-[100px] py-[2px] px-2`}
                          >
                            {newStats[selectedTime]?.total_accounts_change}%
                          </span>
                        )}{" "}
                        {selectedTime === "last_1week" && "Last One Week"}
                        {selectedTime === "last_1month" && "Last One Month"}
                        {selectedTime === "last_6months" && "Last Six Months"}
                        {selectedTime === "last_1year" && "Last One Year"}
                      </p>
                    </div>
                  </div>
                  <div className="border border-[#E4E4E4] bg-[#E4E4E4] rounded-[8px] py-10 pl-6 flex items-center gap-6">
                    <img
                      src={DiscountsBlack}
                      alt=""
                      className="p-4 bg-[#62646A4D] rounded-full"
                      // onClick={() => {
                      //   setSelectedName("learn");
                      //   selectedTime === "1w" &&
                      //     setSelected(stats?.oneWeekDoc[2]);
                      //   selectedTime === "1m" &&
                      //     setSelected(stats?.oneMonthDoc[2]);
                      //   selectedTime === "6m" &&
                      //     setSelected(stats?.sixMonthsDoc[2]);
                      //   selectedTime === "1yr" &&
                      //     setSelected(stats?.oneYearDoc[2]);
                      // }}
                    />
                    <div className="flex flex-col gap-2">
                      <p className="font-medium text-[0.75rem] text-[#333333]">
                        TOTAL NO. OF DISCOUNTS
                      </p>
                      <p className="font-semibold text-3xl text-[#333333]">
                        {newStats[
                          selectedTime
                        ]?.total_discounted_accounts.toLocaleString()}
                      </p>
                      <p className="text-[#1E1E1EB2] font-medium text-[0.75rem]">
                        {selectedTime !== "total" && (
                          <span
                            className={`${
                              newStats[selectedTime]
                                ?.total_discounted_accounts_change < 1
                                ? "text-[#B41007] bg-[#B410071A]"
                                : "text-[#3EB211] bg-[#6EDA441A]"
                            } font-medium text-[0.875rem] rounded-[100px] py-[2px] px-2`}
                          >
                            {
                              newStats[selectedTime]
                                ?.total_discounted_accounts_change
                            }
                            %
                          </span>
                        )}{" "}
                        {selectedTime === "last_1week" && "Last One Week"}
                        {selectedTime === "last_1month" && "Last One Month"}
                        {selectedTime === "last_6months" && "Last Six Months"}
                        {selectedTime === "last_1year" && "Last One Year"}
                      </p>
                    </div>
                  </div>
                  <div className="border border-[#E4E4E4] bg-[#E4E4E4] rounded-[8px] py-10 pl-6 flex items-center gap-6">
                    <img
                      src={ViolationsBlack}
                      alt=""
                      className="p-4 bg-[#62646A4D] rounded-full"
                      // onClick={() => {
                      //   setSelectedName("reward");
                      //   selectedTime === "1w" &&
                      //     setSelected(stats?.oneWeekDoc[3]);
                      //   selectedTime === "1m" &&
                      //     setSelected(stats?.oneMonthDoc[3]);
                      //   selectedTime === "6m" &&
                      //     setSelected(stats?.sixMonthsDoc[3]);
                      //   selectedTime === "1yr" &&
                      //     setSelected(stats?.oneYearDoc[3]);
                      // }}
                    />
                    <div className="flex flex-col gap-2">
                      <p className="font-medium text-[0.75rem] text-[#333333]">
                        TOTAL NO. OF VIOLATIONS
                      </p>
                      <p className="font-semibold text-3xl text-[#333333]">
                        {newStats[
                          selectedTime
                        ]?.total_violations.toLocaleString()}
                      </p>
                      <p className="text-[#1E1E1EB2] font-medium text-[0.75rem]">
                        {selectedTime !== "total" && (
                          <span
                            className={`${
                              newStats[selectedTime]?.total_violations_change <
                              1
                                ? "text-[#B41007] bg-[#B410071A]"
                                : "text-[#3EB211] bg-[#6EDA441A]"
                            } font-medium text-[0.875rem] rounded-[100px] py-[2px] px-2`}
                          >
                            {newStats[selectedTime]?.total_violations_change}%
                          </span>
                        )}{" "}
                        {selectedTime === "last_1week" && "Last One Week"}
                        {selectedTime === "last_1month" && "Last One Month"}
                        {selectedTime === "last_6months" && "Last Six Months"}
                        {selectedTime === "last_1year" && "Last One Year"}
                      </p>
                    </div>
                  </div>
                  <div className="border border-[#E4E4E4] bg-[#E4E4E4] rounded-[8px] py-10 pl-6 flex items-center gap-6">
                    <img
                      src={ReceiptBlack}
                      alt=""
                      className="p-4 bg-[#62646A4D] rounded-full"
                      // onClick={() => {
                      //   setSelectedName("advocate");
                      //   selectedTime === "1w" &&
                      //     setSelected(stats?.oneWeekDoc[4]);
                      //   selectedTime === "1m" &&
                      //     setSelected(stats?.oneMonthDoc[4]);
                      //   selectedTime === "6m" &&
                      //     setSelected(stats?.sixMonthsDoc[4]);
                      //   selectedTime === "1yr" &&
                      //     setSelected(stats?.oneYearDoc[4]);
                      // }}
                    />
                    <div className="flex flex-col gap-2">
                      <p className="font-medium text-[0.75rem] text-[#333333]">
                        TOTAL NO. OF RECEIPTS
                      </p>
                      <p className="font-semibold text-3xl text-[#333333]">
                        {newStats[
                          selectedTime
                        ]?.total_receipts.toLocaleString()}
                      </p>
                      <p className="text-[#1E1E1EB2] font-medium text-[0.75rem]">
                        {selectedTime !== "total" && (
                          <span
                            className={`${
                              newStats[selectedTime]?.total_receipts_change < 1
                                ? "text-[#B41007] bg-[#B410071A]"
                                : "text-[#3EB211] bg-[#6EDA441A]"
                            } font-medium text-[0.875rem] rounded-[100px] py-[2px] px-2`}
                          >
                            {newStats[selectedTime]?.total_receipts_change}%
                          </span>
                        )}{" "}
                        {selectedTime === "last_1week" && "Last One Week"}
                        {selectedTime === "last_1month" && "Last One Month"}
                        {selectedTime === "last_6months" && "Last Six Months"}
                        {selectedTime === "last_1year" && "Last One Year"}
                      </p>
                    </div>
                  </div>
                  <div className="border border-[#E4E4E4] bg-[#E4E4E4] rounded-[8px] py-10 pl-6 flex items-center gap-6">
                    <img
                      src={PayoutsBlack}
                      alt=""
                      className="p-4 bg-[#62646A4D] rounded-full"
                      // onClick={() => {
                      //   setSelectedName("activity");
                      //   selectedTime === "1w" &&
                      //     setSelected(stats?.oneWeekDoc[5]);
                      //   selectedTime === "1m" &&
                      //     setSelected(stats?.oneMonthDoc[5]);
                      //   selectedTime === "6m" &&
                      //     setSelected(stats?.sixMonthsDoc[5]);
                      //   selectedTime === "1yr" &&
                      //     setSelected(stats?.oneYearDoc[5]);
                      // }}
                    />
                    <div className="flex flex-col gap-2">
                      <p className="font-medium text-[0.75rem] text-[#333333]">
                        TOTAL NO. OF PAYOUTS
                      </p>
                      <p className="font-semibold text-3xl text-[#333333]">
                        {newStats[selectedTime]?.total_payouts.toLocaleString()}
                      </p>
                      <p className="text-[#1E1E1EB2] font-medium text-[0.75rem]">
                        {selectedTime !== "total" && (
                          <span
                            className={`${
                              newStats[selectedTime]?.total_payouts_change < 1
                                ? "text-[#B41007] bg-[#B410071A]"
                                : "text-[#3EB211] bg-[#6EDA441A]"
                            } font-medium text-[0.875rem] rounded-[100px] py-[2px] px-2`}
                          >
                            {newStats[selectedTime]?.total_payouts_change}%
                          </span>
                        )}{" "}
                        {selectedTime === "last_1week" && "Last One Week"}
                        {selectedTime === "last_1month" && "Last One Month"}
                        {selectedTime === "last_6months" && "Last Six Months"}
                        {selectedTime === "last_1year" && "Last One Year"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="mt-14 border border-[#D4D4D4] p-6 rounded-[8px]">
              <div className="mb-[13px]">
                <div className="flex flex-col lg:flex-row items-center gap-[20px] mb-4">
                  <div className="relative">
                    <Menu>
                      <MenuButton className="flex items-center justify-between gap-[10px] bg-[#6EDA441A] border-[#6EDA44] w-[13rem] border rounded-[8px] p-2 font-poppins font-medium text-[#333333] lg:text-[0.875rem] text-[0.75rem]">
                        {filter1 === "registered_users" && "REGISTERED USERS"}
                        {filter1 === "trade_accounts" && "TRADING ACCOUNTS"}
                        {filter1 === "challenge_1000" && "CHALLENGE 1,000"}
                        {filter1 === "challenge_5000" && "CHALLENGE 5,000"}
                        {filter1 === "challenge_10000" && "CHALLENGE 10,000"}
                        {filter1 === "challenge_25000" && "CHALLENGE 25,000"}
                        {filter1 === "challenge_50000" && "CHALLENGE 50,000"}
                        {filter1 === "classic_1000" && "CLASSIC 1,000"}
                        {filter1 === "classic_3000" && "CLASSIC 3,000"}
                        {filter1 === "classic_5000" && "CLASSIC 5,000"}
                        {filter1 === "classic_10000" && "CLASSIC 10,000"}
                        {filter1 === "classic_25000" && "CLASSIC 25,000"}
                        {filter1 === "classic_50000" && "CLASSIC 50,000"}
                        <img src={Chevron} alt="" />
                      </MenuButton>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <MenuItems
                          as="div"
                          className="absolute w-[11.2rem] z-10 lg:mt-[1rem] mt-[0.1rem] lg:mr-[5rem] origin-top-right divide-y divide-gray-300 rounded-[0.75rem] bg-[#6EDA441A] border border-[#6EDA44] ring-1 ring-black ring-opacity-5 focus:outline-none flex flex-col"
                        >
                          {filter2 !== "registered_users" && (
                            <MenuItem
                              onClick={() => setFilter1("registered_users")}
                              as="div"
                              className="hover:bg-[#6EDA44] rounded-t-[0.75rem] cursor-pointer hover:text-white p-2 font-poppins font-medium text-[#333333] lg:text-[0.875rem] text-[0.75rem]"
                            >
                              REGISTERED USERS
                            </MenuItem>
                          )}
                          {filter2 !== "trade_accounts" && (
                            <MenuItem
                              onClick={() => setFilter1("trade_accounts")}
                              as="div"
                              className={`${
                                filter2 === "registered_users" &&
                                "rounded-t-[0.75rem]"
                              } hover:bg-[#6EDA44] cursor-pointer hover:text-white p-2 font-poppins font-medium text-[#333333] lg:text-[0.875rem] text-[0.75rem]`}
                            >
                              TRADING ACCOUNTS
                            </MenuItem>
                          )}
                          {filter2 !== "challenge_1000" && (
                            <MenuItem
                              onClick={() => setFilter1("challenge_1000")}
                              as="div"
                              className="hover:bg-[#6EDA44] cursor-pointer hover:text-white p-2 font-poppins font-medium text-[#333333] lg:text-[0.875rem] text-[0.75rem]"
                            >
                              CHALLENGE 1,000
                            </MenuItem>
                          )}
                          {filter2 !== "challenge_5000" && (
                            <MenuItem
                              onClick={() => setFilter1("challenge_5000")}
                              as="div"
                              className="hover:bg-[#6EDA44] cursor-pointer hover:text-white p-2 font-poppins font-medium text-[#333333] lg:text-[0.875rem] text-[0.75rem]"
                            >
                              CHALLENGE 5,000
                            </MenuItem>
                          )}
                          {filter2 !== "challenge_10000" && (
                            <MenuItem
                              onClick={() => setFilter1("challenge_10000")}
                              as="div"
                              className={`${
                                filter2 === "activity" && "rounded-b-[0.75rem]"
                              } hover:bg-[#6EDA44] cursor-pointer hover:text-white p-2 font-poppins font-medium text-[#333333] lg:text-[0.875rem] text-[0.75rem]`}
                            >
                              CHALLENGE 10,000
                            </MenuItem>
                          )}
                          {filter2 !== "challenge_25000" && (
                            <MenuItem
                              onClick={() => setFilter1("challenge_25000")}
                              as="div"
                              className="hover:bg-[#6EDA44] cursor-pointer hover:text-white p-2 font-poppins font-medium text-[#333333] lg:text-[0.875rem] text-[0.75rem]"
                            >
                              CHALLENGE 25,000
                            </MenuItem>
                          )}
                          {filter2 !== "challenge_50000" && (
                            <MenuItem
                              onClick={() => setFilter1("challenge_50000")}
                              as="div"
                              className="hover:bg-[#6EDA44] cursor-pointer hover:text-white p-2 font-poppins font-medium text-[#333333] lg:text-[0.875rem] text-[0.75rem]"
                            >
                              CHALLENGE 50,000
                            </MenuItem>
                          )}
                          {filter2 !== "classic_1000" && (
                            <MenuItem
                              onClick={() => setFilter1("classic_1000")}
                              as="div"
                              className="hover:bg-[#6EDA44] cursor-pointer hover:text-white p-2 font-poppins font-medium text-[#333333] lg:text-[0.875rem] text-[0.75rem]"
                            >
                              CLASSIC 1,000
                            </MenuItem>
                          )}
                          {filter2 !== "classic_3000" && (
                            <MenuItem
                              onClick={() => setFilter1("classic_3000")}
                              as="div"
                              className="hover:bg-[#6EDA44] cursor-pointer hover:text-white p-2 font-poppins font-medium text-[#333333] lg:text-[0.875rem] text-[0.75rem]"
                            >
                              CLASSIC 3,000
                            </MenuItem>
                          )}
                          {filter2 !== "classic_5000" && (
                            <MenuItem
                              onClick={() => setFilter1("classic_5000")}
                              as="div"
                              className="hover:bg-[#6EDA44] cursor-pointer hover:text-white p-2 font-poppins font-medium text-[#333333] lg:text-[0.875rem] text-[0.75rem]"
                            >
                              CLASSIC 5,000
                            </MenuItem>
                          )}
                          {filter2 !== "classic_10000" && (
                            <MenuItem
                              onClick={() => setFilter1("classic_10000")}
                              as="div"
                              className="hover:bg-[#6EDA44] cursor-pointer hover:text-white p-2 font-poppins font-medium text-[#333333] lg:text-[0.875rem] text-[0.75rem]"
                            >
                              CLASSIC 10,000
                            </MenuItem>
                          )}
                          {filter2 !== "classic_25000" && (
                            <MenuItem
                              onClick={() => setFilter1("classic_25000")}
                              as="div"
                              className={`${
                                filter2 === "classic_50000" &&
                                "rounded-b-[0.75rem]"
                              } hover:bg-[#6EDA44] cursor-pointer hover:text-white p-2 font-poppins font-medium text-[#333333] lg:text-[0.875rem] text-[0.75rem]`}
                            >
                              CLASSIC 25,000
                            </MenuItem>
                          )}
                          {filter2 !== "classic_50000" && (
                            <MenuItem
                              onClick={() => setFilter1("classic_50000")}
                              as="div"
                              className="hover:bg-[#6EDA44] rounded-b-[0.75rem] cursor-pointer hover:text-white p-2 font-poppins font-medium text-[#333333] lg:text-[0.875rem] text-[0.75rem]"
                            >
                              CLASSIC 50,000
                            </MenuItem>
                          )}
                        </MenuItems>
                      </Transition>
                    </Menu>
                  </div>
                  <div className="relative">
                    <Menu>
                      <MenuButton className="flex items-center gap-[10px] justify-between w-[13rem] bg-[#4E1EE31A] border-[#4E1EE3] border rounded-[8px] p-2 font-poppins font-medium text-[#333333] lg:text-[0.875rem] text-[0.75rem]">
                        {filter2 === "registered_users" && "REGISTERED USERS"}
                        {filter2 === "trade_accounts" && "TRADING ACCOUNTS"}
                        {filter2 === "challenge_1000" && "CHALLENGE 1,000"}
                        {filter2 === "challenge_5000" && "CHALLENGE 5,000"}
                        {filter2 === "challenge_10000" && "CHALLENGE 10,000"}
                        {filter2 === "challenge_25000" && "CHALLENGE 25,000"}
                        {filter2 === "challenge_50000" && "CHALLENGE 50,000"}
                        {filter2 === "classic_1000" && "CLASSIC 1,000"}
                        {filter2 === "classic_3000" && "CLASSIC 3,000"}
                        {filter2 === "classic_5000" && "CLASSIC 5,000"}
                        {filter2 === "classic_10000" && "CLASSIC 10,000"}
                        {filter2 === "classic_25000" && "CLASSIC 25,000"}
                        {filter2 === "classic_50000" && "CLASSIC 50,000"}
                        <img src={Chevron} alt="" />
                      </MenuButton>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <MenuItems
                          as="div"
                          className="absolute bg-[#4E1EE31A] border border-[#4E1EE3] w-[11.2rem] z-10 lg:mt-[1rem] mt-[0.1rem] lg:mr-[5rem] origin-top-right divide-y divide-gray-300 rounded-[0.75rem] ring-1 ring-black ring-opacity-5 focus:outline-none flex flex-col"
                        >
                          {filter1 !== "registered_users" && (
                            <MenuItem
                              onClick={() => setFilter2("registered_users")}
                              as="div"
                              className="hover:bg-[#4E1EE3] rounded-t-[0.75rem] cursor-pointer hover:text-white p-2 font-poppins font-medium text-[#333333] lg:text-[0.875rem] text-[0.75rem]"
                            >
                              REGISTERED USERS
                            </MenuItem>
                          )}
                          {filter1 !== "trade_accounts" && (
                            <MenuItem
                              onClick={() => setFilter2("trade_accounts")}
                              as="div"
                              className={`${
                                filter1 === "registered_users" &&
                                "rounded-t-[0.75rem]"
                              } hover:bg-[#4E1EE3] cursor-pointer hover:text-white p-2 font-poppins font-medium text-[#333333] lg:text-[0.875rem] text-[0.75rem]`}
                            >
                              TRADING ACCOUNTS
                            </MenuItem>
                          )}
                          {filter1 !== "challenge_1000" && (
                            <MenuItem
                              onClick={() => setFilter2("challenge_1000")}
                              as="div"
                              className="hover:bg-[#4E1EE3] cursor-pointer hover:text-white p-2 font-poppins font-medium text-[#333333] lg:text-[0.875rem] text-[0.75rem]"
                            >
                              CHALLENGE 1,000
                            </MenuItem>
                          )}
                          {filter1 !== "challenge_5000" && (
                            <MenuItem
                              onClick={() => setFilter2("challenge_5000")}
                              as="div"
                              className="hover:bg-[#4E1EE3] cursor-pointer hover:text-white p-2 font-poppins font-medium text-[#333333] lg:text-[0.875rem] text-[0.75rem]"
                            >
                              CHALLENGE 5,000
                            </MenuItem>
                          )}
                          {filter1 !== "challenge_10000" && (
                            <MenuItem
                              onClick={() => setFilter2("challenge_10000")}
                              as="div"
                              className={`${
                                filter1 === "activity" && "rounded-b-[0.75rem]"
                              } hover:bg-[#4E1EE3] cursor-pointer hover:text-white p-2 font-poppins font-medium text-[#333333] lg:text-[0.875rem] text-[0.75rem]`}
                            >
                              CHALLENGE 10,000
                            </MenuItem>
                          )}
                          {filter1 !== "challenge_25000" && (
                            <MenuItem
                              onClick={() => setFilter2("challenge_25000")}
                              as="div"
                              className="hover:bg-[#4E1EE3]  cursor-pointer hover:text-white p-2 font-poppins font-medium text-[#333333] lg:text-[0.875rem] text-[0.75rem]"
                            >
                              CHALLENGE 25,000
                            </MenuItem>
                          )}
                          {filter1 !== "challenge_50000" && (
                            <MenuItem
                              onClick={() => setFilter2("challenge_50000")}
                              as="div"
                              className="hover:bg-[#4E1EE3]  cursor-pointer hover:text-white p-2 font-poppins font-medium text-[#333333] lg:text-[0.875rem] text-[0.75rem]"
                            >
                              CHALLENGE 50,000
                            </MenuItem>
                          )}
                          {filter1 !== "classic_1000" && (
                            <MenuItem
                              onClick={() => setFilter2("classic_1000")}
                              as="div"
                              className="hover:bg-[#4E1EE3]  cursor-pointer hover:text-white p-2 font-poppins font-medium text-[#333333] lg:text-[0.875rem] text-[0.75rem]"
                            >
                              CLASSIC 1,000
                            </MenuItem>
                          )}
                          {filter1 !== "classic_3000" && (
                            <MenuItem
                              onClick={() => setFilter2("classic_3000")}
                              as="div"
                              className="hover:bg-[#4E1EE3]  cursor-pointer hover:text-white p-2 font-poppins font-medium text-[#333333] lg:text-[0.875rem] text-[0.75rem]"
                            >
                              CLASSIC 3,000
                            </MenuItem>
                          )}
                          {filter1 !== "classic_5000" && (
                            <MenuItem
                              onClick={() => setFilter2("classic_5000")}
                              as="div"
                              className="hover:bg-[#4E1EE3]  cursor-pointer hover:text-white p-2 font-poppins font-medium text-[#333333] lg:text-[0.875rem] text-[0.75rem]"
                            >
                              CLASSIC 5,000
                            </MenuItem>
                          )}
                          {filter1 !== "classic_10000" && (
                            <MenuItem
                              onClick={() => setFilter2("classic_10000")}
                              as="div"
                              className="hover:bg-[#4E1EE3]  cursor-pointer hover:text-white p-2 font-poppins font-medium text-[#333333] lg:text-[0.875rem] text-[0.75rem]"
                            >
                              CLASSIC 10,000
                            </MenuItem>
                          )}
                          {filter1 !== "classic_25000" && (
                            <MenuItem
                              onClick={() => setFilter2("classic_25000")}
                              as="div"
                              className={`${
                                filter1 === "classic_50000" &&
                                "rounded-b-[0.75rem]"
                              } hover:bg-[#4E1EE3]  cursor-pointer hover:text-white p-2 font-poppins font-medium text-[#333333] lg:text-[0.875rem] text-[0.75rem]`}
                            >
                              CLASSIC 25,000
                            </MenuItem>
                          )}
                          {filter1 !== "classic_50000" && (
                            <MenuItem
                              onClick={() => setFilter2("classic_50000")}
                              as="div"
                              className="hover:bg-[#4E1EE3] rounded-b-[0.75rem] cursor-pointer hover:text-white p-2 font-poppins font-medium text-[#333333] lg:text-[0.875rem] text-[0.75rem]"
                            >
                              CLASSIC 50,000
                            </MenuItem>
                          )}
                        </MenuItems>
                      </Transition>
                    </Menu>
                  </div>
                </div>
                <div className="flex justify-end mb-4">
                  <div className="flex items-center border border-[#E4E4E4] rounded-[8px] font-poppins text-[0.75rem] p-[2px]">
                    <p
                      onClick={() => setGraphSelectedTime("last_week_data")}
                      className={`py-2 cursor-pointer px-4 ${
                        graphSelectedTime === "last_week_data" &&
                        "bg-[#F1F1F1] border border-[#E4E4E4] rounded-[8px]"
                      }`}
                    >
                      1 W
                    </p>
                    <p
                      onClick={() => setGraphSelectedTime("last_month_data")}
                      className={`py-2 cursor-pointer px-4 ${
                        graphSelectedTime === "last_month_data" &&
                        "bg-[#F1F1F1] border border-[#E4E4E4] rounded-[8px]"
                      }`}
                    >
                      1 M
                    </p>
                    <p
                      onClick={() => setGraphSelectedTime("last_6_months_data")}
                      className={`py-2 cursor-pointer px-4 ${
                        graphSelectedTime === "last_6_months_data" &&
                        "bg-[#F1F1F1] border border-[#E4E4E4] rounded-[8px]"
                      }`}
                    >
                      6 M
                    </p>
                    <p
                      onClick={() => setGraphSelectedTime("last_year_data")}
                      className={`py-2 cursor-pointer px-4 ${
                        graphSelectedTime === "last_year_data" &&
                        "bg-[#F1F1F1] border border-[#E4E4E4] rounded-[8px]"
                      }`}
                    >
                      1 YR
                    </p>
                    <p
                      onClick={() => setGraphSelectedTime("total_data")}
                      className={`py-2 cursor-pointer px-4 ${
                        graphSelectedTime === "total_data" &&
                        "bg-[#F1F1F1] border border-[#E4E4E4] rounded-[8px]"
                      }`}
                    >
                      ALL
                    </p>
                  </div>
                </div>
              </div>
              {graphLoading ? (
                <div className="w-[100%] h-[50vh] flex items-center justify-center">
                  <Triangle
                    visible={true}
                    height="80"
                    width="80"
                    color="#3EB211"
                    ariaLabel="triangle-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                  />
                </div>
              ) : (
                <div className="w-[90%]">
                  <MainLineChart
                    data={selectedGraphData?.map((dat) => ({
                      name: dat["date"],
                      uv: dat[filter1],
                      pv: dat[filter2],
                    }))}
                    name={
                      graphSelectedTime === "last_week_data"
                        ? "1 Week"
                        : graphSelectedTime === "last_month_data"
                        ? "1 Month"
                        : graphSelectedTime === "last_6_months_data"
                        ? "6 Months"
                        : graphSelectedTime === "last_year_data"
                        ? "1 Year"
                        : "Total"
                    }
                  />
                </div>
              )}
            </div>

            <div className="mt-14">
              <div className="flex justify-end mb-4">
                <div className="flex items-center border border-[#E4E4E4] rounded-[8px] font-poppins text-[0.75rem] p-[2px]">
                  {/* <p
                  onClick={() => setGraphSelectedTime("1w")}
                  className={`py-2 cursor-pointer px-4 ${
                    graphSelectedTime === "1w" &&
                    "bg-[#F1F1F1] border border-[#E4E4E4] rounded-[8px]"
                  }`}
                >
                  1 W
                </p> */}
                  <p
                    onClick={() => setBottomTime("last_month")}
                    className={`py-2 cursor-pointer px-4 ${
                      bottomTime === "last_month" &&
                      "bg-[#F1F1F1] border border-[#E4E4E4] rounded-[8px]"
                    }`}
                  >
                    1 M
                  </p>
                  <p
                    onClick={() => setBottomTime("last_6_months")}
                    className={`py-2 cursor-pointer px-4 ${
                      bottomTime === "last_6_months" &&
                      "bg-[#F1F1F1] border border-[#E4E4E4] rounded-[8px]"
                    }`}
                  >
                    6 M
                  </p>
                  <p
                    onClick={() => setBottomTime("last_year")}
                    className={`py-2 cursor-pointer px-4 ${
                      bottomTime === "last_year" &&
                      "bg-[#F1F1F1] border border-[#E4E4E4] rounded-[8px]"
                    }`}
                  >
                    1 YR
                  </p>
                  <p
                    onClick={() => setBottomTime("total")}
                    className={`py-2 cursor-pointer px-4 ${
                      bottomTime === "total" &&
                      "bg-[#F1F1F1] border border-[#E4E4E4] rounded-[8px]"
                    }`}
                  >
                    ALL
                  </p>
                </div>
              </div>
              <div className="flex flex-col lg:flex-row items-center gap-4">
                <div className="border border-[#D4D4D4] p-6 rounded-[8px] w-full">
                  <p className="font-medium text-[0.75rem] uppercase text-[#333333]">
                    % of users that got an account after 4 weeks of registration
                  </p>
                  <p className="text-[2rem] font-semibold text-[#333333] mb-2">
                    {bottomTime === "total"
                      ? bottomStats[
                          bottomTime
                        ]?.percentage_with_accounts?.toFixed(2)
                      : bottomStats[bottomTime]?.percentage_with_accounts
                          .current}
                    %
                  </p>
                  <p className="text-[0.75rem] font-medium text-[#1E1E1EB2]">
                    <span
                      className={`text-[0.875rem] ${
                        bottomStats[bottomTime]?.percentage_with_accounts
                          .change > 0
                          ? "bg-[#72E52C1A] text-[#3EB211]"
                          : bottomTime === "total"
                          ? ""
                          : "bg-[#B410071A] text-[#B41007]"
                      } rounded-[100px] py-0.5 px-2`}
                    >
                      {bottomTime !== "total" &&
                        bottomStats[bottomTime]?.percentage_with_accounts
                          .change}
                      {bottomTime !== "total" && "%"}
                    </span>
                    {"  "}
                    {bottomTime === "total" && ""}
                    {bottomTime === "last_month" && "Last One Month"}
                    {bottomTime === "last_6_months" && "Last Six Months"}
                    {bottomTime === "last_year" && "Last One Year"}
                  </p>
                </div>
                <div className="border border-[#D4D4D4] p-6 rounded-[8px] w-full">
                  <p className="font-medium text-[0.75rem] uppercase text-[#333333]">
                    % of users that refer users within 4 weeks of registering
                  </p>
                  <p className="text-[2rem] font-semibold text-[#333333] mb-2">
                    {bottomTime === "total"
                      ? bottomStats[
                          bottomTime
                        ]?.percentage_with_referrals.toFixed(2)
                      : bottomStats[bottomTime]?.percentage_with_referrals
                          .current}
                    %
                  </p>
                  <p className="text-[0.75rem] font-medium text-[#1E1E1EB2]">
                    {bottomTime !== "total" ? (
                      <span
                        className={`text-[0.875rem] ${
                          bottomStats[bottomTime]?.percentage_with_referrals
                            .change > 0
                            ? "bg-[#72E52C1A] text-[#3EB211]"
                            : "bg-[#B410071A] text-[#B41007]"
                        } rounded-[100px] py-0.5 px-2`}
                      >
                        {bottomStats[bottomTime]?.percentage_with_referrals
                          .change || 0}
                        %
                      </span>
                    ) : (
                      <span
                        className={`text-[0.875rem] ${
                          bottomStats[bottomTime]?.percentage_with_referrals
                            .change > 0
                            ? "bg-[#72E52C1A] text-[#3EB211]"
                            : "bg-[] text-[#B41007]"
                        } rounded-[100px] py-0.5 px-2`}
                      ></span>
                    )}{" "}
                    {bottomTime === "total" && ""}
                    {bottomTime === "last_month" && "Last One Month"}
                    {bottomTime === "last_6_months" && "Last Six Months"}
                    {bottomTime === "last_year" && "Last One Year"}
                  </p>
                </div>
                <div className="border border-[#D4D4D4] p-6 rounded-[8px] w-full">
                  <p className="font-medium text-[0.75rem] uppercase text-[#333333]">
                    % of users that referred 2 or more traders within 4
                    weeks of registering
                  </p>
                  <p className="text-[2rem] font-semibold text-[#333333] mb-2">
                    {bottomTime === "total"
                      ? bottomStats[
                          bottomTime
                        ]?.percentage_with_multiple_referrals.toFixed(2)
                      : bottomStats[bottomTime]
                          ?.percentage_with_multiple_referrals.current}
                    %
                  </p>
                  <p className="text-[0.75rem] font-medium text-[#1E1E1EB2]">
                    {bottomTime === "total" ? (
                      <span
                        className={`text-[0.875rem] ${
                          bottomStats[bottomTime]
                            ?.percentage_with_multiple_referrals.change > 0
                            ? "bg-[#72E52C1A] text-[#3EB211]"
                            : "bg-[#] text-[#B41007]"
                        } rounded-[100px] py-0.5 px-2`}
                      ></span>
                    ) : (
                      <span
                        className={`text-[0.875rem] ${
                          bottomStats[bottomTime]
                            ?.percentage_with_multiple_referrals.change > 0
                            ? "bg-[#72E52C1A] text-[#3EB211]"
                            : "bg-[#B410071A] text-[#B41007]"
                        } rounded-[100px] py-0.5 px-2`}
                      >
                        {bottomStats[bottomTime]
                          ?.percentage_with_multiple_referrals.change || 0}
                        %
                      </span>
                    )}{" "}
                    {bottomTime === "total" && ""}
                    {bottomTime === "last_month" && "Last One Month"}
                    {bottomTime === "last_6_months" && "Last Six Months"}
                    {bottomTime === "last_year" && "Last One Year"}
                  </p>
                </div>
              </div>
              <div className="flex flex-col lg:flex-row items-center gap-4 mt-4">
                <div className="border border-[#D4D4D4] p-6 rounded-[8px] w-full">
                  <p className="font-medium text-[0.75rem] uppercase text-[#333333]">
                    No. of accounts that violated
                  </p>
                  <p className="text-[2rem] font-semibold text-[#333333] mb-2">
                    {bottomTime === "total"
                      ? bottomStats[bottomTime]?.num_violations.toLocaleString()
                      : bottomStats[bottomTime]?.num_violations.current}{" "}
                    accounts
                  </p>
                  <p className="text-[0.75rem] font-medium text-[#1E1E1EB2]">
                    <span
                      className={`text-[0.875rem] ${
                        bottomStats[bottomTime]?.num_violations.change > 0
                          ? "bg-[#72E52C1A] text-[#3EB211]"
                          : bottomTime === "total"
                          ? ""
                          : "bg-[#B410071A] text-[#B41007]"
                      } rounded-[100px] py-0.5 px-2`}
                    >
                      {bottomTime !== "total" &&
                        bottomStats[bottomTime]?.num_violations.change}
                      {bottomTime !== "total" && "%"}
                    </span>{" "}
                    {bottomTime === "total" && ""}
                    {bottomTime === "last_month" && "Last One Month"}
                    {bottomTime === "last_6_months" && "Last Six Months"}
                    {bottomTime === "last_year" && "Last One Year"}
                  </p>
                </div>
                <div className="border border-[#D4D4D4] p-6 rounded-[8px] w-full">
                  <p className="font-medium text-[0.75rem] uppercase text-[#333333]">
                    Average time to violation for ALL account sizes
                  </p>
                  <p className="text-[2rem] font-semibold text-[#333333] mb-2">
                    {avgVioTime[bottomTime]} days
                  </p>
                  <p className="text-[0.75rem] font-medium text-[#1E1E1EB2]">
                    <span className="text-[0.875rem] text-[#B41007] rounded-[100px] py-0.5 px-2">
                      {/* 9.00% */}
                    </span>{" "}
                    {/* This week */}
                  </p>
                </div>
                <div className="border border-[#D4D4D4] p-6 rounded-[8px] w-full">
                  <p className="font-medium text-[0.75rem] uppercase text-[#333333]">
                    % of traders that referred 2 or more traders within 4
                    weeks of becoming a trader
                  </p>
                  <p className="text-[2rem] font-semibold text-[#333333] mb-2">
                    {bottomTime === "total"
                      ? bottomStats[
                          bottomTime
                        ]?.percentage_with_multiple_referrals_traders.toFixed(2)
                      : bottomStats[bottomTime]
                          ?.percentage_with_multiple_referrals_traders.current}
                    %
                  </p>
                  <p className="text-[0.75rem] font-medium text-[#1E1E1EB2]">
                    {bottomTime === "total" ? (
                      <span
                        className={`text-[0.875rem] ${
                          bottomStats[bottomTime]
                            ?.percentage_with_multiple_referrals_traders
                            .change > 0
                            ? "bg-[#72E52C1A] text-[#3EB211]"
                            : "bg-[#] text-[#B41007]"
                        } rounded-[100px] py-0.5 px-2`}
                      ></span>
                    ) : (
                      <span
                        className={`text-[0.875rem] ${
                          bottomStats[bottomTime]
                            ?.percentage_with_multiple_referrals_traders
                            .change > 0
                            ? "bg-[#72E52C1A] text-[#3EB211]"
                            : "bg-[#B410071A] text-[#B41007]"
                        } rounded-[100px] py-0.5 px-2`}
                      >
                        {bottomStats[bottomTime]
                          ?.percentage_with_multiple_referrals_traders.change ||
                          0}
                        %
                      </span>
                    )}{" "}
                    {bottomTime === "total" && ""}
                    {bottomTime === "last_month" && "Last One Month"}
                    {bottomTime === "last_6_months" && "Last Six Months"}
                    {bottomTime === "last_year" && "Last One Year"}
                  </p>
                </div>
              </div>
              <div className="flex flex-col lg:flex-row items-center gap-4 mt-4">
                <div className="border border-[#D4D4D4] p-6 rounded-[8px] w-full lg:w-[33%]">
                  <p className="font-medium text-[0.75rem] uppercase text-[#333333]">
                    % of users that got an account within 4 weeks of registration
                  </p>
                  <p className="text-[2rem] font-semibold text-[#333333] mb-2">
                    {bottomTime === "total"
                      ? bottomStats[
                          bottomTime
                        ]?.percentage_with_accounts_within_4weeks?.toFixed(2)
                      : bottomStats[bottomTime]?.percentage_with_accounts_within_4weeks
                          .current}
                    %
                  </p>
                  <p className="text-[0.75rem] font-medium text-[#1E1E1EB2]">
                    <span
                      className={`text-[0.875rem] ${
                        bottomStats[bottomTime]?.percentage_with_accounts_within_4weeks
                          .change > 0
                          ? "bg-[#72E52C1A] text-[#3EB211]"
                          : bottomTime === "total"
                          ? ""
                          : "bg-[#B410071A] text-[#B41007]"
                      } rounded-[100px] py-0.5 px-2`}
                    >
                      {bottomTime !== "total" &&
                        bottomStats[bottomTime]?.percentage_with_accounts_within_4weeks
                          .change}
                      {bottomTime !== "total" && "%"}
                    </span>
                    {"  "}
                    {bottomTime === "total" && ""}
                    {bottomTime === "last_month" && "Last One Month"}
                    {bottomTime === "last_6_months" && "Last Six Months"}
                    {bottomTime === "last_year" && "Last One Year"}
                  </p>
                </div>
                
               
              </div>
            </div>
          </div>
        ) : (
          <div className="font-poppins text-center flex items-center justify-center h-[88vh] text-[1.5rem] font-medium text-gray-500 px-10">
            Welcome {email}, the pages you have access to are on the left. Click
            to navigate to the desired page
          </div>
        )}
      </div>
      <Dialog
        open={selected !== null}
        onClose={() => setSelected(null)}
        className="relative z-50"
      >
        {/* The backdrop, rendered as a fixed sibling to the panel container */}
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

        {/* Full-screen container to center the panel */}
        <div className="fixed inset-0 flex items-center justify-center p-4">
          {/* The actual dialog panel  */}
          <Dialog.Panel className="mx-auto rounded bg-white p-4 h-[500px] lg:w-[700px] overflow-y-scroll">
            <div className="grid grid-cols-2 gap-4">
              {selected?.map((sel) => (
                <div
                  key={sel._id}
                  className="border rounded-lg border-[#333333] p-2 text-[0.75rem] font-poppins"
                >
                  <p className="mb-2 break-words">
                    <span className="font-semibold">User Email: </span>
                    {sel.email || sel.userEmail}
                  </p>
                  <p>
                    <span className="font-semibold">
                      {selectedName === "user"
                        ? "Join Date"
                        : selectedName === "reward"
                        ? "Reward"
                        : selectedName === "learn"
                        ? "Video Completed"
                        : selectedName === "quiz"
                        ? "Score"
                        : "Date Submitted"}
                      :{" "}
                    </span>
                    {selectedName !== "advocate" && selectedName !== "activity"
                      ? sel.score ||
                        sel.name ||
                        sel.amount ||
                        sel.title ||
                        convertDate(sel.createdAt)
                      : sel.date}
                  </p>
                </div>
              ))}
            </div>
            {selected?.length < 1 && (
              <div className="flex items-center justify-center w-[100%] h-[100%] font-poppins lg:text-[2.5rem] text-center text-[1.5rem] font-semibold text-gray-500">
                There is no data to display
              </div>
            )}
          </Dialog.Panel>
        </div>
      </Dialog>
    </div>
  );
}

export default Analytics;
