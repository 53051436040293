import React from "react";
import Sorting from "../assets/f7_sort-up (1).svg";
// import More from "../assets/uiw_more (1).svg";
import { convertDateTime } from "../../utils/ConvertDate";

function DvaReciTable({ data, setIsOpen, setSelected }) {
  return (
    <div className="w-[100%] mt-[24px]">
      <table className="w-[100%] hidden lg:table">
        <thead className="bg-[#F1F1F1] text-[0.75rem]">
          <tr>
            {/* <td className="pl-[16px] py-[8px] text-[#62646A99] font-poppins lg:w-[12.5%]">
              {" "}
              <div className="flex items-center gap-[8px]">
                User ID
                <img src={Sorting} alt="" />
              </div>
            </td> */}
            <td className="py-[8px] text-[#62646A99] font-poppins lg:w-[12.5%]">
              {" "}
              <div className="flex items-center justify-center gap-[8px]">
                Sender
                <img src={Sorting} alt="" />
              </div>
            </td>
            <td className="py-[8px] text-[#62646A99] font-poppins lg:w-[12.5%]">
              {" "}
              <div className="flex items-center justify-center gap-[8px]">
                Sender Email
                <img src={Sorting} alt="" />
              </div>
            </td>
            <td className="py-[8px] text-[#62646A99] font-poppins lg:w-[12.5%]">
              {" "}
              <div className="flex items-center justify-center gap-[8px]">
                Sender Bank
                <img src={Sorting} alt="" />
              </div>
            </td>
            <td className="py-[8px] text-[#62646A99] font-poppins lg:w-[12.5%]">
              {" "}
              <div className="flex items-center text-center justify-center gap-[8px]">
                Payment Time
                <img src={Sorting} alt="" />
              </div>
            </td>
            <td className="py-[8px] text-[#62646A99] font-poppins lg:w-[12.5%]">
              {" "}
              <div className="flex items-center justify-center gap-[8px]">
                Amount
                <img src={Sorting} alt="" />
              </div>
            </td>
            {/* <td className="py-[8px] text-[#62646A99] font-poppins lg:w-[12.5%]">
              {" "}
              <div className="flex items-center text-center justify-center gap-[8px]">
                Paystack Reference
                <img src={Sorting} alt="" />
              </div>
            </td> */}
            <td className="py-[8px] text-[#62646A99] font-poppins lg:w-[12.5%]">
              {" "}
              <div className="flex items-center text-center justify-center gap-[8px]">
                Paystack Status
                <img src={Sorting} alt="" />
              </div>
            </td>

            <td className="py-[8px] text-[#62646A99] font-poppins lg:w-[10%]">
              {" "}
              <div className="flex items-center justify-center gap-[8px]">
                Comment
                <img src={Sorting} alt="" />
              </div>
            </td>
            <td className="py-[8px] text-[#62646A99] font-poppins lg:w-[10%]">
              {" "}
              <div className="flex items-center justify-center gap-[8px]">
                Admin Status
                <img src={Sorting} alt="" />
              </div>
            </td>
            {/* <td className="py-[8px] text-[#62646A99] font-poppins lg:w-[10%]"></td> */}
          </tr>
        </thead>
        <tbody className="text-[0.75rem]">
          {data?.map((da) => (
            <tr key={da._id}>
              {/* <td className="pl-[16px] py-[16px] text-[#333333] font-medium font-poppins">
                {da.user}
              </td> */}
              <td className="py-[16px] text-[#333333] font-medium font-poppins text-center">
                {da.sender_name}
              </td>
              <td className="py-[16px] text-[#333333] font-medium font-poppins text-center">
                {da.email}
              </td>
              <td className="py-[16px] text-[#333333] text-center font-medium font-poppins">
                {da.sender_bank}
              </td>
              <td className="py-[16px] text-[#333333] text-center font-medium font-poppins">
                {convertDateTime(da.payment_time)}
              </td>
              <td className="py-[16px] text-[#333333] text-center font-medium font-poppins">
                {da.amount.toLocaleString()}
              </td>
              {/* <td className="py-[16px] text-[#333333] text-center font-medium font-poppins">
                {da.reference}
              </td> */}
              <td className="py-[16px] font-medium font-poppins flex items-center justify-center">
                <p
                  className={`${
                    da.status.toLowerCase() === "success"
                      ? "bg-[#72E52C1A] text-[#72E52C]"
                      : "bg-[#B410071A] text-[#B41007]"
                  } font-medium rounded-[200px] px-2 w-max text-center py-1`}
                >
                  {da.status}
                </p>
              </td>

              <td className="py-[16px] text-[#333333] text-center font-medium font-poppins">
                {da.comment?.length > 0 ? da.comment : "No Comment"}
              </td>
              <td className="py-[16px] font-medium font-poppins flex items-center justify-center">
                <p
                  className={`${
                    da.admin_status.toLowerCase() === "confirmed"
                      ? "bg-[#72E52C1A] text-[#72E52C]"
                      : da.admin_status.toLowerCase() === "unprocessed"
                      ? "bg-[#EDBB071A] text-[#EDBB07]"
                      : "bg-[#B410071A] text-[#B41007]"
                  } font-medium rounded-[200px] px-2 w-max text-center py-1`}
                >
                  {da.admin_status}
                </p>
              </td>

              {/* <td className="text-[#333333] font-medium font-poppins">
                <div
                  onClick={() => {
                    setSelected(da);
                    setIsOpen(true);
                  }}
                  className="py-[16px] flex items-center justify-center cursor-pointer"
                >
                  <img src={More} alt="" />
                </div>
              </td> */}
            </tr>
          ))}
        </tbody>
      </table>
      <div className="lg:hidden flex flex-col gap-[16px] w-[100%]">
        {data.map((da) => (
          <div
            key={da._id}
            className="flex flex-col border border-[#C8C8C8] rounded-lg p-4 space-y-6 font-poppins"
          >
            {/* <div
              onClick={() => {
                setSelected(da);
                setIsOpen(true);
              }}
              className="flex justify-end cursor-pointer"
            >
              <img src={More} alt="" />
            </div> */}
            <div className="flex items-center justify-between flex-wrap gap-6">
              <div>
                <p className="text-[0.75rem] text-[#62646A99] mb-1">USER ID</p>
                <p className="text-[0.875rem] text-[#333333] font-medium">
                  {" "}
                  {da.user}
                </p>
              </div>
              <div>
                <p className="text-[0.75rem] text-[#62646A99] mb-1">SENDER</p>
                <p className="text-[0.875rem] text-[#333333] font-medium">
                  {da.sender_name}
                </p>
              </div>
            </div>
            <div className="flex items-center justify-between flex-wrap gap-6">
              <div>
                <p className="text-[0.75rem] text-[#62646A99] mb-1">
                  SENDER BANK
                </p>
                <p className="text-[0.875rem] text-[#333333] font-medium">
                  {" "}
                  {da.sender_bank}
                </p>
              </div>
              <div>
                <p className="text-[0.75rem] text-[#62646A99] mb-1">
                  PAYMENT TIME
                </p>
                <p className="text-[0.875rem] text-[#333333] font-medium">
                  {" "}
                  {convertDateTime(da.payment_time)}
                </p>
              </div>
            </div>
            <div className="flex items-center justify-between">
              <div>
                <p className="text-[0.75rem] text-[#62646A99] mb-1">AMOUNT</p>
                <p className="text-[0.875rem] text-[#333333] font-medium">
                  {da.amount.toLocaleString()}
                </p>
              </div>
              <div>
                <p className="text-[0.75rem] text-[#62646A99] mb-1">
                  PAYSTACK REFERENCE
                </p>
                <p className="text-[0.875rem] text-[#333333] font-medium">
                  {da.reference}
                </p>
              </div>
            </div>
            <div className="flex items-center justify-between">
              <div>
                <p className="text-[0.75rem] text-[#62646A99] mb-1">
                  PAYSTACK STATUS
                </p>
                <p
                  className={`${
                    da.status.toLowerCase() === "success"
                      ? "bg-[#72E52C1A] text-[#72E52C]"
                      : "bg-[#B410071A] text-[#B41007]"
                  } text-[0.875rem] font-medium rounded-[200px] px-2 w-max text-center py-1`}
                >
                  {da.status}
                </p>
              </div>
              <div>
                <p className="text-[0.75rem] text-[#62646A99] mb-1">
                  ADMIN STATUS
                </p>
                <p
                  className={`${
                    da.admin_status.toLowerCase() === "confirmed"
                      ? "bg-[#72E52C1A] text-[#72E52C]"
                      : da.admin_status.toLowerCase() === "unprocessed"
                      ? "bg-[#EDBB071A] text-[#EDBB07]"
                      : "bg-[#B410071A] text-[#B41007]"
                  } text-[0.875rem] font-medium rounded-[200px] px-2 w-max text-center py-1`}
                >
                  {da.admin_status}
                </p>
              </div>
            </div>
            <div className="flex items-center justify-between">
              <div>
                <p className="text-[0.75rem] text-[#62646A99] mb-1">COMMENT</p>
                <p className="text-[0.875rem] text-[#333333] font-medium">
                  {da.comment?.length > 0 ? da.comment : "No comment"}
                </p>
              </div>
              <div>
                <p className="text-[0.75rem] text-[#62646A99] mb-1">
                  SENDER EMAIL
                </p>
                <p className="text-[0.875rem] text-[#333333] font-medium">
                  {da.email}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default DvaReciTable;
