import React from "react";
import Sorting from "../assets/f7_sort-up (1).svg";
import More from "../assets/uiw_more (1).svg";
import convertDate from "../../utils/ConvertDate";

function DiscountsTable({ data, setSelectedDiscount, setOpenSelected }) {
  return (
    <div className="w-[100%] mt-[24px] text-[0.87rem]">
      <table className="w-[100%] hidden xl:table">
        <thead className="bg-[#F1F1F1] text-[0.8rem]">
          <tr>
            <td className="pl-[16px] py-[8px] text-[#62646A99] font-poppins lg:w-[12.5%]">
              {" "}
              <div className="flex items-center gap-[8px]">
                Code
                <img src={Sorting} alt="" />
              </div>
            </td>
            <td className="py-[8px] text-[#62646A99] font-poppins lg:w-[12.5%]">
              {" "}
              <div className="flex items-center justify-center gap-[8px]">
                Discount %
                <img src={Sorting} alt="" />
              </div>
            </td>
            <td className="py-[8px] text-[#62646A99] font-poppins lg:w-[12.5%]">
              {" "}
              <div className="flex items-center justify-center gap-[8px]">
                Targeted A/C
                <img src={Sorting} alt="" />
              </div>
            </td>
            <td className="py-[8px] text-[#62646A99] font-poppins lg:w-[12.5%]">
              {" "}
              <div className="flex items-center justify-center gap-[8px]">
                Total Used
                <img src={Sorting} alt="" />
              </div>
            </td>
            <td className="py-[8px] text-[#62646A99] font-poppins lg:w-[12.5%]">
              {" "}
              <div className="flex items-center justify-center gap-[8px]">
                Created On
                <img src={Sorting} alt="" />
              </div>
            </td>
            <td className="py-[8px] text-[#62646A99] font-poppins lg:w-[12.5%]">
              {" "}
              <div className="flex items-center justify-center gap-[8px]">
                Expiry Date
                <img src={Sorting} alt="" />
              </div>
            </td>
            <td className="py-[8px] text-[#62646A99] font-poppins lg:w-[12.5%]">
              {" "}
              <div className="flex items-center justify-center gap-[8px]">
                Description
                <img src={Sorting} alt="" />
              </div>
            </td>
            {!localStorage
              .getItem("groups")
              ?.includes("View-only Super Admin") && (
              <td className="py-[8px] text-[#62646A99] font-poppins lg:w-[12.5%]"></td>
            )}
          </tr>
        </thead>
        <tbody>
          {data?.map((da) => (
            <tr key={da._id}>
              <td className="pl-[16px] py-[16px] text-[#333333] font-medium font-poppins">
                {da.code}
              </td>
              <td className="py-[16px] text-[#333333] font-medium font-poppins text-center">
                {da.discount_percentage}
              </td>
              <td className="py-[16px] text-[#333333] text-center font-medium font-poppins">
                {da.num_of_accounts || 0}
              </td>
              <td className="py-[16px] font-medium text-center font-poppins">
                {da.total_used}
              </td>
              <td className="py-[16px] font-medium text-center font-poppins">
                {convertDate(da.created)}
              </td>
              <td className="py-[16px] font-medium text-center font-poppins">
                {convertDate(da.expiry_date)}
              </td>
              <td className="py-[16px] font-medium text-center font-poppins">
                {da.description?.length > 0 ? da.description : "No Description"}
              </td>
              {!localStorage
                .getItem("groups")
                ?.includes("View-only Super Admin") && (
                <td className="text-[#333333] font-medium font-poppins">
                  <div
                    onClick={() => {
                      setSelectedDiscount(da);
                      setOpenSelected(true);
                    }}
                    className="py-[16px] flex items-center justify-center cursor-pointer"
                  >
                    <img src={More} alt="" />
                  </div>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
      <div className="xl:hidden lg:mt-10 flex flex-col items-center gap-[16px] w-[100%]">
        {data.map((da) => (
          <div
            key={da._id}
            className="flex flex-col border border-[#C8C8C8] rounded-lg p-4 space-y-6 font-poppins w-[100%] lg:w-[80%]"
          >
            {!localStorage
              .getItem("groups")
              ?.includes("View-only Super Admin") && (
              <div
                onClick={() => {
                  setSelectedDiscount(da);
                  setOpenSelected(true);
                }}
                className="flex justify-end cursor-pointer"
              >
                <img src={More} alt="" />
              </div>
            )}
            <div className="flex items-center justify-between flex-wrap gap-6">
              <div>
                <p className="text-[0.75rem] text-[#62646A99] mb-1">CODE</p>
                <p className="text-[0.875rem] text-[#333333] font-medium">
                  {da.code}
                </p>
              </div>
              <div>
                <p className="text-[0.75rem] text-[#62646A99] mb-1">
                  DISCOUNT %
                </p>
                <p className="text-[0.875rem] text-[#333333] font-medium">
                  {da.discount_percentage}
                </p>
              </div>
            </div>
            <div className="flex items-center justify-between flex-wrap gap-6">
              <div>
                <p className="text-[0.75rem] text-[#62646A99] mb-1">
                  TARGETED A/C
                </p>
                <p className="text-[0.875rem] text-[#333333] font-medium">
                  {" "}
                  {da.num_of_accounts || 0}
                </p>
              </div>
              <div>
                <p className="text-[0.75rem] text-[#62646A99] mb-1">
                  TOTAL USED
                </p>
                <p className="text-[0.875rem] text-[#333333] font-medium">
                  {da.total_used}
                </p>
              </div>
            </div>
            <div className="flex items-center justify-between flex-wrap gap-6">
              <div>
                <p className="text-[0.75rem] text-[#62646A99] mb-1">
                  CREATED ON
                </p>
                <p className="text-[0.875rem] text-[#333333] font-medium">
                  {" "}
                  {convertDate(da.created)}
                </p>
              </div>
              <div>
                <p className="text-[0.75rem] text-[#62646A99] mb-1">
                  EXPIRY DATE
                </p>
                <p className="text-[0.875rem] text-[#333333] font-medium">
                  {" "}
                  {convertDate(da.expiry_date)}
                </p>
              </div>
            </div>
            <div className="flex items-center justify-between flex-wrap gap-6">
              <div>
                <p className="text-[0.75rem] text-[#62646A99] mb-1">
                  DESCRIPTION
                </p>
                <p className="text-[0.875rem] text-[#333333] font-medium">
                  {" "}
                  {da.description?.length > 0
                    ? da.description
                    : "No description"}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default DiscountsTable;
