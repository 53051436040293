/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Sidebar from "../components/Sidebar";
import Topbar from "../components/Topbar";
import axiosInstance from "../axios";
import { Triangle } from "react-loader-spinner";
import ChatbotFeedTable from "../components/Tables/ChatbotFeedTable";
import { Dialog, DialogPanel } from "@headlessui/react";

function ChatbotFeed() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState([]);
  const [nextUrl, setNextUrl] = useState(null);
  const [prevUrl, setPrevUrl] = useState(null);
  const [newUrl, setNewUrl] = useState(null);
  const [total, setTotal] = useState(null);
  const [selectedUser, setSelectedUser] = useState();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setLoading(true);
    axiosInstance
      .get(`${newUrl || "/dashboard/support/disclaimer/"}`)
      .then((res) => {
        console.log(res);
        setLoading(false);
        setNextUrl(res.data.next);
        setPrevUrl(res.data.previous);
        setTotal(res.data.count);
        setData(res.data.results);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [newUrl]);

  return (
    <div className="flex h-[100vh]">
      <Sidebar />
      <div className="lg:w-[82%] w-[100%]">
        <Topbar />
        <div className="lg:p-8 p-4 h-[88vh] overflow-y-scroll">
          <div className="border border-[#E4E4E4] rounded-lg font-poppins lg:p-6 p-3">
            <h1 className="text-center text-[1.125rem] font-medium text-[#333333] mb-6">
              Chat bot Feedbacks
            </h1>
            <div className="flex items-center justify-end gap-5 font-poppins font-medium lg:mb-5">
              {prevUrl && (
                <button
                  onClick={() => {
                    setNewUrl(prevUrl);
                  }}
                  className="bg-white text-[#3EB211] border border-[#3EB211] py-2 px-4 rounded-lg"
                >
                  Prev
                </button>
              )}
              {nextUrl && (
                <div>
                  <p>
                    Page {nextUrl?.split("=")[1] - 1} of{" "}
                    {Math.round(total / 50)}
                  </p>
                </div>
              )}
              {nextUrl && (
                <button
                  onClick={() => {
                    setNewUrl(nextUrl);
                  }}
                  className="bg-[#3EB211] border border-[#3EB211] text-white rounded-lg py-2 px-4"
                >
                  Next
                </button>
              )}
            </div>

            {loading ? (
              <div className="w-[100%] h-[50vh] flex items-center justify-center">
                <Triangle
                  visible={true}
                  height="80"
                  width="80"
                  color="#3EB211"
                  ariaLabel="triangle-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              </div>
            ) : (
              <div className="lg:border border-[#E4E4E4] rounded-lg">
                <ChatbotFeedTable
                  data={data}
                  setSelectedUser={setSelectedUser}
                  setOpen={setOpen}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <Dialog
        open={open}
        onClose={() => {
          setSelectedUser();
          setOpen(false);
        }}
        className="relative z-50"
      >
        <div className="fixed inset-0 flex w-screen items-center bg-black/50 justify-center p-4">
          <DialogPanel className="rounded-lg font-poppins space-y-4 border bg-white p-6 w-[600px] lg:max-h-[700px] max-h-[600px] overflow-y-scroll">
            <div className="flex items-center justify-between">
              {/* <CSVLink data={CSVdataModal} filename={"users_data.csv"}>
                <button className="text-[#3EB211] font-poppins font-medium">
                  Export CSV
                </button>
              </CSVLink> */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="17"
                viewBox="0 0 17 17"
                fill="none"
                className="cursor-pointer"
                onClick={() => {
                  setSelectedUser();
                  setOpen(false);
                }}
              >
                <path
                  d="M12.7001 3.97283C12.6385 3.91103 12.5652 3.862 12.4846 3.82854C12.4039 3.79509 12.3175 3.77787 12.2301 3.77787C12.1428 3.77787 12.0564 3.79509 11.9757 3.82854C11.8951 3.862 11.8218 3.91103 11.7601 3.97283L8.50015 7.22616L5.24015 3.96616C5.17843 3.90444 5.10515 3.85548 5.02451 3.82208C4.94387 3.78868 4.85744 3.77148 4.77015 3.77148C4.68286 3.77148 4.59643 3.78868 4.51579 3.82208C4.43514 3.85548 4.36187 3.90444 4.30015 3.96616C4.23843 4.02789 4.18947 4.10116 4.15606 4.1818C4.12266 4.26245 4.10547 4.34888 4.10547 4.43616C4.10547 4.52345 4.12266 4.60988 4.15606 4.69053C4.18947 4.77117 4.23843 4.84444 4.30015 4.90616L7.56015 8.16616L4.30015 11.4262C4.23843 11.4879 4.18947 11.5612 4.15606 11.6418C4.12266 11.7224 4.10547 11.8089 4.10547 11.8962C4.10547 11.9835 4.12266 12.0699 4.15606 12.1505C4.18947 12.2312 4.23843 12.3044 4.30015 12.3662C4.36187 12.4279 4.43514 12.4768 4.51579 12.5102C4.59643 12.5437 4.68286 12.5608 4.77015 12.5608C4.85744 12.5608 4.94387 12.5437 5.02451 12.5102C5.10515 12.4768 5.17843 12.4279 5.24015 12.3662L8.50015 9.10616L11.7601 12.3662C11.8219 12.4279 11.8951 12.4768 11.9758 12.5102C12.0564 12.5437 12.1429 12.5608 12.2301 12.5608C12.3174 12.5608 12.4039 12.5437 12.4845 12.5102C12.5652 12.4768 12.6384 12.4279 12.7001 12.3662C12.7619 12.3044 12.8108 12.2312 12.8442 12.1505C12.8776 12.0699 12.8948 11.9835 12.8948 11.8962C12.8948 11.8089 12.8776 11.7224 12.8442 11.6418C12.8108 11.5612 12.7619 11.4879 12.7001 11.4262L9.44015 8.16616L12.7001 4.90616C12.9535 4.65283 12.9535 4.22616 12.7001 3.97283Z"
                  fill="#333333"
                />
              </svg>
            </div>
            <div className="flex items-center flex-col justify-center border-b border-b-[#E4E4E4] pb-6">
              <p className="text-center text-[1.125rem] font-medium text-[#333333] mb-2">
                Feedback Details
              </p>
              <p
                className={`${
                  selectedUser?.vote === "upvote"
                    ? "bg-[#72E52C1A] text-[#72E52C]"
                    : "bg-[#B410071A] text-[#B41007]"
                } text-[0.875rem] text-center w-max font-medium rounded-[200px] px-2 py-1 outline-none`}
              >
                {selectedUser?.vote}
              </p>
            </div>
            <div className="flex items-center rounded-lg flex-col justify-center border border-[#E4E4E4] p-6 space-y-6">
              <div className="w-full space-y-6">
                <div className="flex items-center justify-between flex-wrap gap-6 w-[100%]">
                  <div>
                    <p className="text-[0.75rem] text-[#62646A99] mb-1">USER</p>
                    <p className="text-[0.875rem] text-[#333333] font-medium">
                      {selectedUser?.preceding_user_message?.name || "No name"}
                    </p>
                  </div>
                  <div>
                    <p className="text-[0.75rem] text-[#62646A99] mb-1">
                      EMAIL ADDRESS
                    </p>
                    <p className="text-[0.875rem] text-[#333333] font-medium">
                      {" "}
                      {selectedUser?.preceding_user_message?.email ||
                        "No email"}
                    </p>
                  </div>
                </div>
                <div className="flex items-center justify-between flex-wrap gap-6 w-[100%]">
                  <div>
                    <p className="text-[0.75rem] text-[#62646A99] mb-1">
                      RATED MESSAGE
                    </p>
                    <p className="text-[0.875rem] text-[#333333] font-medium">
                      {selectedUser?.rated_message?.content || "No message"}
                    </p>
                  </div>
                  <div>
                    <p className="text-[0.75rem] text-[#62646A99] mb-1">
                      PRECEDING USER MESSAGE
                    </p>
                    <p className="text-[0.875rem] text-[#333333] font-medium">
                      {" "}
                      {selectedUser?.preceding_user_message?.content ||
                        "No message"}
                    </p>
                  </div>
                </div>
                <div className="flex items-center justify-between w-[100%]">
                  <div>
                    <p className="text-[0.75rem] text-[#62646A99] mb-1">
                      FEEDBACK
                    </p>
                    <p className="text-[0.875rem] text-[#333333] font-medium">
                      {selectedUser?.feedback || "No feedback"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </DialogPanel>
        </div>
      </Dialog>
    </div>
  );
}

export default ChatbotFeed;
