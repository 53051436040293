import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

function MainLineChart({ data, name }) {
  // console.log(window.innerWidth)
  // const data = [
  //   {
  //     name: "Jan",
  //     uv: 4000,
  //     pv: 0,
  //   },
  //   {
  //     name: "Feb",
  //     uv: 0,
  //     pv: 1398,
  //   },
  //   {
  //     name: "Mar",
  //     uv: 2000,
  //     pv: 0,
  //   },
  //   {
  //     name: "Apr",
  //     uv: 0,
  //     pv: 3908,
  //   },
  //   {
  //     name: "May",
  //     uv: 1890,
  //     pv: 0,
  //   },
  //   {
  //     name: "Jun",
  //     uv: 0,
  //     pv: 3800,
  //   },
  //   {
  //     name: "Jul",
  //     uv: 3490,
  //     pv: 0,
  //   },
  // ];
  return (
    <ResponsiveContainer aspect={window.innerWidth > 640 ? 2.3 : 1}>
      <LineChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 0,
          left: 0,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          fontSize={"0.525rem"}
          dataKey="name"
          fontFamily={"Poppins, sans-serif"}
          angle={-25}
          textAnchor="end"
        />
        <YAxis fontSize={"0.625rem"} fontFamily={"Poppins, sans-serif"} />
        <Tooltip />
        {/* <Legend verticalAlign='top' align='right' iconType='circle' iconSize={"0.75rem"}/> */}
        <Line
          type="monotone"
          name={name}
          dataKey="pv"
          stroke="#4E1EE3"
          strokeWidth={2}
          activeDot={{ r: 8 }}
        />
        <Line
          type="monotone"
          name={name}
          dataKey="uv"
          stroke="#6EDA44"
          strokeWidth={2}
        />
      </LineChart>
    </ResponsiveContainer>
  );
}

export default MainLineChart;
