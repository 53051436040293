/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Sidebar from "../components/Sidebar";
import Topbar from "../components/Topbar";
import axiosInstance from "../axios";
import { Triangle } from "react-loader-spinner";

function UserStats() {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [nextUrl, setNextUrl] = useState(null);
  const [prevUrl, setPrevUrl] = useState(null);
  const [newUrl, setNewUrl] = useState(null);
  const [total, setTotal] = useState(null);

  useEffect(() => {
    setLoading(true);
    axiosInstance
      .get(`${newUrl || "dashboard/referral-platform/"}`)
      .then((res) => {
        console.log(res);
        setLoading(false);
        setNextUrl(res.data.next);
        setPrevUrl(res.data.previous);
        setTotal(res.data.count);
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [newUrl]);
  return (
    <div className="flex h-[100vh]">
      <Sidebar />
      <div className="lg:w-[82%] w-[100%]">
        <Topbar />
        <div className="p-8">
          <div className="border border-[#E4E4E4] rounded-lg p-6">
            <h1 className="text-center text-[1.125rem] font-medium text-[#333333] mb-6">
              Places users hear about us
            </h1>
            {loading ? (
              <div className="w-[100%] h-[50vh] flex items-center justify-center">
                <Triangle
                  visible={true}
                  height="80"
                  width="80"
                  color="#3EB211"
                  ariaLabel="triangle-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              </div>
            ) : (
              <div className="flex flex-col lg:flex-row lg:items-center gap-6 justify-between border border-[#E4E4E4] rounded-lg p-6">
                <div>
                  <p className="text-[#62646A99] text-[0.75rem] font-medium mb-4">
                    GOOGLE SEARCH
                  </p>
                  <p className="text-[#333333] font-medium text-[0.875rem]">
                    {data?.goolgle_search?.toLocaleString()}
                  </p>
                </div>
                <div>
                  <p className="text-[#62646A99] text-[0.75rem] font-medium mb-4">
                    FRIENDS
                  </p>
                  <p className="text-[#333333] font-medium text-[0.875rem]">
                    {data?.friends?.toLocaleString()}
                  </p>
                </div>
                <div>
                  <p className="text-[#62646A99] text-[0.75rem] font-medium mb-4">
                    TRADING GROUPS
                  </p>
                  <p className="text-[#333333] font-medium text-[0.875rem]">
                    {data?.trading_groups?.toLocaleString()}
                  </p>
                </div>
                <div>
                  <p className="text-[#62646A99] text-[0.75rem] font-medium mb-4">
                    SOCIAL MEDIA
                  </p>
                  <p className="text-[#333333] font-medium text-[0.875rem]">
                    {data?.social_media?.toLocaleString()}
                  </p>
                </div>
                <div>
                  <p className="text-[#62646A99] text-[0.75rem] font-medium mb-4">
                    REFERRALS
                  </p>
                  <p className="text-[#333333] font-medium text-[0.875rem]">
                    {data?.referrals?.toLocaleString()}
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserStats;
