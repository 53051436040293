import React from "react";
import Sorting from "../assets/f7_sort-up (1).svg";
// import More from "../assets/uiw_more (1).svg";
import { convertDateTime } from "../../utils/ConvertDate";

function AdminsLogTable({ data }) {
  return (
    <div className="w-[100%] mt-[24px]">
      <table className="w-[100%] hidden lg:table">
        <thead className="bg-[#F1F1F1] text-[0.875rem]">
          <tr>
            <td className="pl-[16px] py-[8px] text-[#62646A99] font-poppins lg:w-[16.7%]">
              {" "}
              <div className="flex items-center gap-[8px]">
                Admin ID
                <img src={Sorting} alt="" />
              </div>
            </td>
            <td className="py-[8px] text-[#62646A99] font-poppins lg:w-[16.7%]">
              {" "}
              <div className="flex items-center justify-center gap-[8px]">
                Admin Email
                <img src={Sorting} alt="" />
              </div>
            </td>
            <td className="py-[8px] text-[#62646A99] font-poppins lg:w-[16.7%]">
              {" "}
              <div className="flex items-center justify-center gap-[8px]">
                User Email
                <img src={Sorting} alt="" />
              </div>
            </td>
            {/* <td className="py-[8px] text-[#62646A99] font-poppins lg:w-[16.7%]">
              {" "}
              <div className="flex items-center justify-center gap-[8px]">
                Device type
                <img src={Sorting} alt="" />
              </div>
            </td> */}
            <td className="py-[8px] text-[#62646A99] font-poppins lg:w-[16.7%]">
              {" "}
              <div className="flex items-center justify-center gap-[8px]">
                Action Type
                <img src={Sorting} alt="" />
              </div>
            </td>
            <td className="py-[8px] text-[#62646A99] font-poppins lg:w-[16.7%] text-center">
              {" "}
              <div className="flex items-center justify-center gap-[8px]">
                Description
                <img src={Sorting} alt="" />
              </div>
            </td>
            <td className="py-[8px] text-[#62646A99] font-poppins lg:w-[16.7%]">
              {" "}
              <div className="flex items-center justify-center gap-[8px]">
                Date
                <img src={Sorting} alt="" />
              </div>
            </td>
            {/* {!localStorage
                  .getItem("groups")
                  ?.includes("View-only Super Admin") && (
                  <td className="py-[8px] text-[#62646A99] font-poppins lg:w-[14.3%]"></td>
                )} */}
          </tr>
        </thead>
        <tbody className="text-[0.875rem]">
          {data?.map((da) => (
            <tr key={da._id}>
              <td className="pl-[16px] py-[16px] text-[#333333] font-medium font-poppins">
                {da.admin}
              </td>
              <td className="py-[16px] text-[#333333] font-medium font-poppins text-center">
                {da.admin_email}
              </td>
              <td className="py-[16px] text-[#333333] font-medium font-poppins text-center">
                {da.user_email}
              </td>
              {/* <td className="py-[16px] text-[#333333] font-medium font-poppins text-center">
                {da.device_type}
              </td> */}
              <td className="py-[16px] text-[#333333] text-center font-medium font-poppins">
                {da.action_type}
              </td>
              <td className="py-[16px] text-[#333333] text-center font-medium font-poppins">
                {da.description}
              </td>
              <td className="py-[16px] text-[#333333] font-medium font-poppins text-center">
                {convertDateTime(da.timestamp)}
              </td>

              {/* {!localStorage
                    .getItem("groups")
                    ?.includes("View-only Super Admin") && (
                    <td className="text-[#333333] font-medium font-poppins">
                      <div
                        onClick={() => {
                          setSelectedUser(da);
                          setOpen(true);
                        }}
                        className="py-[16px] flex items-center justify-center cursor-pointer"
                      >
                        <img src={More} alt="" />
                      </div>
                    </td>
                  )} */}
            </tr>
          ))}
        </tbody>
      </table>
      <div className="lg:hidden flex flex-col gap-[16px] w-[100%]">
        {data.map((da) => (
          <div
            key={da._id}
            className="flex flex-col border border-[#C8C8C8] rounded-lg p-4 space-y-6 font-poppins"
          >
            {/* {!localStorage
                  .getItem("groups")
                  ?.includes("View-only Super Admin") && (
                  <div
                    onClick={() => {
                      setSelectedUser(da);
                      setOpen(true);
                    }}
                    className="flex justify-end cursor-pointer"
                  >
                    <img src={More} alt="" />
                  </div>
                )} */}
            <div className="flex items-center justify-between flex-wrap gap-6">
              <div>
                <p className="text-[0.75rem] text-[#62646A99] mb-1">ADMIN ID</p>
                <p className="text-[0.875rem] text-[#333333] font-medium">
                  {" "}
                  {da.admin}
                </p>
              </div>
              <div>
                <p className="text-[0.75rem] text-[#62646A99] mb-1">
                  USER EMAIL
                </p>
                <p className="text-[0.875rem] text-[#333333] font-medium">
                  {da.admin_email}
                </p>
              </div>
            </div>
            <div className="flex items-center justify-between flex-wrap gap-6">
              <div>
                <p className="text-[0.75rem] text-[#62646A99] mb-1">
                  DEVICE TYPE
                </p>
                <p className="text-[0.875rem] text-[#333333] font-medium">
                  {" "}
                  {da.device_type}
                </p>
              </div>
              <div>
                <p className="text-[0.75rem] text-[#62646A99] mb-1">
                  ACTION TYPE
                </p>
                <p className="text-[0.875rem] text-[#333333] font-medium">
                  {da.action_type}
                </p>
              </div>
            </div>
            <div className="flex items-center justify-between">
              <div>
                <p className="text-[0.75rem] text-[#62646A99] mb-1">
                  DESCRIPTION
                </p>
                <p className="text-[0.875rem] text-[#333333] font-medium">
                  {da.description}
                </p>
              </div>
              <div>
                <p className="text-[0.75rem] text-[#62646A99] mb-1">DATE</p>
                <p className="text-[0.875rem] text-[#333333] font-medium text-center">
                  {convertDateTime(da.timestamp)}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default AdminsLogTable;
